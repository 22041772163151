<div class="o-content">
  <div class="contentTopPaymentsBalances"
  (swipeleft)="onSwipe($event, selectedTile)"
  (swiperight)="onSwipe($event, selectedTile)">
    <div *ngIf="selectedTile === 0" class="contentTopPaymentsBalances__item o-block">
      <div class="svgAndTextWrapper">
        <span class="o-text5-blueDark">Notch Pay Balance</span>
        <div class="tooltip-container">
          <img style="margin-top: 1px" src="assets/question/question_grey&16.svg" alt="tooltip">
          <div class="tooltip-content">Available Notch Pay balance to transfer and pay game fees for post game reports approved.</div>
        </div>
      </div>
      <div class='contentTopPaymentsBalances__item__info'>
        <div class='contentTopPaymentsBalances__item__info__amount'>{{ (payS.balance$|async)?.current | currency }}</div>
      </div>
    </div>
    <div *ngIf="selectedTile === 1" class="contentTopPaymentsBalances__item o-block">
      <div class="svgAndTextWrapper">
        <span class="o-text5-blueDark">Pending Inflows</span>
        <div class="tooltip-container">
          <img style="margin-top: 1px" src="assets/question/question_grey&16.svg" alt="tooltip">
          <div class="tooltip-content">Payments in transit and not yet available in your Notch Pay balance.</div>
        </div>
      </div>
      <!-- <svgAndText text='Pending Inflows' rightSvgName='question_grey&16' typeText='text5-blueDark' marginForRightSvg='auto'></svgAndText> -->
      <div class='contentTopPaymentsBalances__item__info'>
        <div class='contentTopPaymentsBalances__item__info__amount'>{{ (payS.balance$|async)?.pending | currency }}</div>
      </div>
    </div>
    <div *ngIf="selectedTile === 2" class="contentTopPaymentsBalances__itemDue o-block">
      <div class="contentTopPaymentsBalances__textArrow">
        <!-- <svgAndText text='Pending Outflows' rightSvgName='question_grey&16' typeText='text5-blueDark'></svgAndText> -->
        <div class="svgAndTextWrapper">
          <span class="o-text5-blueDark">Pending Outflows</span>
          <div class="tooltip-container">
            <img style="margin-top: 1px" src="assets/question/question_grey&16.svg" alt="tooltip">
            <div class="tooltip-content">Pending outflows are your projected outgoings for active game assignments not yet started or approved in post game. Go to Reports to view more details.</div>
          </div>
        </div>
        <div class='contentTopPaymentsBalances__item__info__arrows'>
          <meSvg svgName='arrow_squareLeft&24' (click)='toggleProjectedOutgoingsPeriod("left")'></meSvg>
          <meSvg svgName='arrow_squareRight&24' (click)='toggleProjectedOutgoingsPeriod("right")'></meSvg>
        </div>
      </div>
      <div class='contentTopPaymentsBalances__item__info'>
        <div class='contentTopPaymentsBalances__item__info__amount'>{{ (payS.projectedOutgoings$|async)?.totalFees | currency:'USD':true }}</div>
        <div class='contentTopPaymentsBalances__item__info__period'>
          <small style="padding-left: 8px;">{{ getCurrentProjectedOutgoingsPeriod() }}</small>
        </div>
      </div>
    </div>
  </div>
  <div class='contentTopPaymentsBalances contentTopPaymentsBalances__autoTopUp' *ngIf="isAddFundsEnabled()">
    <div class='contentTopPaymentsBalances__autoTopUp'>
        <div class='contentTopPaymentsBalances__autoTopUp__info o-block'
          (click)='goToBalancesAutoTopup()'>
          <svgAndText text='Auto Top-Up' leftSvgName='arrow_twoCircle&20' typeText='text7-blueDark'></svgAndText>
          <div [ngClass]="{
            'status-button--on': autoTopupStatus === 'On',
            'status-button--off': autoTopupStatus === 'Off'
          }">
            {{ autoTopupStatus }}
          </div>
        </div>
        <!-- <div class='tooltip-container-comingSoon'>
          <div class='contentTopPaymentsBalances__autoTopUp__info o-block'
            (click)='payS.openPopupAutoToPup()' style="pointer-events: none;">
            <svgAndText text='Auto Top-Up' leftSvgName='arrow_twoCircle&20' typeText='text7-blueDark'></svgAndText>
          </div>
          <div class='tooltip-content-comingSoon'>Coming Soon <img style="margin-left: 10px" src='assets/vector-lock.svg'>
          </div>
        </div> -->
      <btn text='Add Funds' bcg='newGreen' btnHeight='36' fontSize='16' (emit)='goToBalancesPay()'></btn>
    </div>
</div>
<div class="contentTopPaymentsBalances__tiles">
  <div *ngFor="let tile of tiles; let i = index" 
       class="tile__dot" 
       (click)="selectTile(i)" 
       (swipeleft)="onSwipe($event, i)"
       (swiperight)="onSwipe($event, i)"
       [ngClass]="{ isActive: selectedTile === i }">
  </div>
</div>
<div class="o-subheader"> Recent Transactions</div>
</div>
