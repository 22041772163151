import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-btn-primary',
  imports: [MatTooltipModule],
  templateUrl: './btn-primary.component.html',
  styleUrl: './btn-primary.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class BtnPrimaryComponent {
  @Input() matTooltip!: string;
  @Input() disabled: boolean = false;
  @Output() emit = new EventEmitter();

  constructor() {}

  click() {
    this.emit.emit();
  }
}
