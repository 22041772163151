import { EventEmitter, Injectable } from '@angular/core';
import { MainService } from '@services/main.service';
import { ClassSettingsRequest, IResponse } from '@models/response-and-request';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  EBalance,
  ELinkPayment,
  IAssignorFees,
  IBalance,
  IBalanceForHtml,
  IBalanceOperation,
  IPayer,
  IPaymentMethod,
  IResponseGetProjectedOutgoings,
  ISettingsForTransfers,
  ITestArrForAnalytics,
  ITestArrForCompetitions,
  ITopup,
  ITransfersOfficialSum,
  linkPaymentsItems,
  TLinkPaymentsCamelCase,
  TLinkPaymentsItem,
} from '@app/dir_group_assignor/payments/modelsForPayment';
import { PopupEditChartsComponent } from '@components/__popup-windows/popup-edit-charts/popup-edit-charts.component';
import { PopupAutoTopupComponent } from '@components/__popup-windows/popup-auto-topup/popup-auto-topup.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FundService } from '@services/funds.service';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { TransferApiService } from '@services/transfer.api.service';
import { TransferModel } from '@models/transfer.model';
import { TChoosePeriod } from '@components/__drop_inputs_matSelect/date-range/dateRange';
import { ClassCompetition } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { ClassFilterDrop } from '@components/filters/filters';
import { ForTestService } from '@classes/forTest';
import { ApiCompetitionService } from '@app/dir_group_assignor/competitions/api-competition.service';

@Injectable({ providedIn: 'root' })
export class PaymentService {
  currentLink?: TLinkPaymentsItem;
  private baseUrl = '/api/payments/v1';
  readonly linkPaymentsItems = linkPaymentsItems;
  readonly arrPeriods: Array<TChoosePeriod> = ['Today', 'Last 7 days', 'Last 30 days', 'Last 3 months', 'Last 12 months', 'Month to Date', 'Quarter to Date', 'Year to Date', 'Custom Date Range'];
  http: any;

  constructor(
    private mainS: MainService,
    private apiCompetitionS: ApiCompetitionService,
    private transferApiService: TransferApiService,
    private fundS: FundService,
    private router: Router,
    private dialog: MatDialog,
    private forTestS: ForTestService,
  ) {
    this.getArrCompetition();
    this.checkCurrentLink();
  }

  checkCurrentLink(): void {
    const arr = this.router.url?.split('/');
    const linkPaymentsCamelCase = arr?.filter(Boolean)[1] as TLinkPaymentsCamelCase;
    this.currentLink = { titleCase: ELinkPayment[linkPaymentsCamelCase], upperCase: linkPaymentsCamelCase };
  }

  navigate(link: TLinkPaymentsItem): void {
    this.router.navigate([`payments/${link.upperCase}`]);
  }

  goToAllList(currentUrl: string, queryParams?: any): void { // assignorFees/all   balances/all    officialsFees/all
    if (currentUrl?.toLowerCase().includes('overview')) {
      this.router.navigate([`payments/payers`]);
      return;
    }
    this.router.navigate([`${currentUrl}/all`], { queryParams });
  }

  paymentMethods$ = new BehaviorSubject<any>(null);  
  projectedOutgoings$ = new BehaviorSubject<any>(null);

  // === FOR FILTERS ===================================
  arrCompetition$ = new BehaviorSubject<Array<ClassCompetition>>([]);
  competitionFilterDrop$ = new BehaviorSubject<ClassFilterDrop>(new ClassFilterDrop({ typeFilter: 'competitions' }));

  getArrCompetition(): void {
    this.apiCompetitionS.getArrCompetition().toPromise()
      .then((res) => {
        const arrCompetitions: Array<ClassCompetition> = res?.content || [];
         arrCompetitions.unshift(new ClassCompetition({ id: 'all', competitionName: 'All Competitions' }));
        this.competitionFilterDrop$.next(new ClassFilterDrop({
          typeFilter: 'competitions',
          arrayForDropdown: arrCompetitions,
          valueDrop: arrCompetitions[0],
          default_valueDrop: arrCompetitions[0],
          noDelete: true,
        }));
        this.arrCompetition$.next(arrCompetitions);
      })
      .catch((err) => {
      });
  }

  // === OVERVIEW ===========================================================
  testArrForCompetitions: Array<ITestArrForCompetitions> = [
    { title: 'EDINA SOCCER CLUB 2011 GIRLS...', amount: 47, crown: 'crown_gold&24', percent: 100 },
    { title: 'Minnetonka HS - Dome 7V7-3', amount: 36, crown: 'crown_grey&24', percent: 80 },
    { title: 'West St. Paul Dome', amount: 29, crown: 'crown_brown&24', percent: 70 },
    { title: 'Providence Academy - Dome', amount: 18, percent: 50 },
    { title: 'Braemar - 32', amount: 7, percent: 35 },
    { title: 'Braemar - 30', amount: 5, percent: 25 },
  ];

  // === Officials Fees ==========================================================
  officialFees$ = new BehaviorSubject<number>(0);
  assignorFees$ = new BehaviorSubject<number>(0);
  totalFees$ = new BehaviorSubject<number>(0);
  sumFees$ = new BehaviorSubject<number>(0); // officialFees + assignorFees
  getProjectedOutgoings(settingsRequest: ClassSettingsRequest): Observable<IResponseGetProjectedOutgoings | null> {
    return this.mainS.getProjectedOutgoings(settingsRequest)
      .pipe(
        tap((res) => {
          if (!res) return;
          this.officialFees$.next(res?.officialFees || 0);
          this.assignorFees$.next(res?.assignorFees || 0);
          this.sumFees$.next(res?.officialFees || 0 + res?.assignorFees || 0);
        }),
      );
  }


  // === BALANCES =======================================
  balance$ = new BehaviorSubject<IBalance | null>(null);
  arrBalancesForHtml$ = new BehaviorSubject<Array<IBalanceForHtml>>([]);

  // !!! сейчас пока что без анимации. Потом сделать
  testForAnimationBalance(balance: IBalanceForHtml, type: 'left' | 'right'): void {
    const newArrBalancesForHtml: Array<IBalanceForHtml> = this.arrBalancesForHtml$.getValue()?.map((el, i) => {
      if (type == 'left') {
        el.isActive = balance.serialNumber - 1 == el.serialNumber;
      } else {
        el.isActive = balance.serialNumber + 1 == el.serialNumber;
      }
      return el;
    });
    this.arrBalancesForHtml$.next(newArrBalancesForHtml);
  }

  getBalanceCompetition(competitionId: string): Observable<IBalance | null> {
    return this.mainS.getBalanceCompetition(competitionId)
      .pipe(
        tap((res: IBalance | null) => {
          if (!res) return;
          this.balance$.next(res);
          this.getArrBalancesForHtml(res);
        }),
      );
  }


  getBalanceWithOutCompetition(): Observable<IBalance | null> {
    return this.mainS.getBalanceWithOutCompetition().pipe(
      tap((res: IBalance | null) => {
        if (!res) return;
        this.balance$.next(res);
        this.getArrBalancesForHtml(res);
      })
    );
  }

  getArrBalancesForHtml(balance: IBalance): Array<IBalanceForHtml> {
    let arrBalancesForHtml: Array<IBalanceForHtml> = [];
    Object.entries(balance)?.forEach((el, idx) => {
      const balanceForHtml: IBalanceForHtml = {
        title: el[0] as keyof IBalance,
        coin: '$' + el[1],
        coinForArrow: el[0] == 'current' ? '$4.300' : '$4.300',
        text: EBalance[el[0] as keyof IBalance] || '',
        isActive: el[0] == 'current',
        serialNumber: 0,
      };
      if (el[0] == 'current') balanceForHtml.serialNumber = 1;
      if (el[0] == 'payoutsDue') balanceForHtml.serialNumber = 2;
      if (el[0] == 'available') balanceForHtml.serialNumber = 3;
      if (el[0] == 'current' || el[0] == 'payoutsDue' || el[0] == 'available') arrBalancesForHtml.push(balanceForHtml);
    });
    arrBalancesForHtml = arrBalancesForHtml.sort((el1, el2) => {
      if (el1.serialNumber > el2.serialNumber) return 1;
      else return -1;
    });
    this.arrBalancesForHtml$.next(arrBalancesForHtml);
    return arrBalancesForHtml;
  }


  // === TRANSFER ==========================================
  transfersOfficialSum$ = new BehaviorSubject<ITransfersOfficialSum>({ STRIPE: 0, OFFLINE: 0 });
  transfersData$ = new BehaviorSubject<IResponse<TransferModel>>({});
  transfers$ = new BehaviorSubject<Array<TransferModel>>([]); // ITransfer

  // !!! page payments/officialsFees // transferType: 'STRIPE' => Notch Pay // transferType: 'OFFLINE' => Offline // Officials Fees Paid == Notch Pay + Offline
  getTransfersSum(sendObj?: ISettingsForTransfers): Observable<number | null> {
    return this.transferApiService.getTransfersSum(sendObj)
      .pipe(
        tap((res) => {
          if (!res) return;
          const transfersOfficialSum = this.transfersOfficialSum$.getValue();
          if (sendObj?.transferType == 'STRIPE') {
            this.transfersOfficialSum$.next({ ...transfersOfficialSum, STRIPE: res });
          }
          if (sendObj?.transferType == 'OFFLINE') {
            this.transfersOfficialSum$.next({ ...transfersOfficialSum, OFFLINE: res });
          }
        }),
      );
  }

  getTransfers(settings: ClassSettingsRequest): Observable<IResponse<TransferModel> | null> {
    return this.transferApiService.getTransfers(settings)
      .pipe(
        tap((res) => {
          if (!res) return;
          this.transfersData$.next(res);
          this.transfers$.next(res?.content || []);
        }),
      );
  }


  // === PAYMENT METHODS =============================================================
  allMethodsSub$ = new BehaviorSubject<Array<IPaymentMethod> | null>(null); // с сервера. включая карты и банки
  methodsSub$ = new BehaviorSubject<Array<IPaymentMethod>>([]); // или банки или карты. При переключении fundS.link меняется массив
  getPaymentMethodsCompetition(competitionId: string): Observable<Array<IPaymentMethod> | null> {
    return this.mainS.getPaymentMethodsCompetition(competitionId)
      .pipe(
        tap((res: Array<IPaymentMethod> | null) => {
          this.allMethodsSub$.next(res || []);
          this.getCurrentMethods();
        }),
      );
  }

  // При переключении fundS.link меняется массив // или банки или карты.
  // addNewMethod передать, если нужно добавить в список новый payment метод
  getCurrentMethods(addNewMethod?: IPaymentMethod): void {
    if (addNewMethod) this.allMethodsSub$.next([addNewMethod, ...this.allMethodsSub$.getValue() || []]);
    let newMethods: Array<IPaymentMethod> = [];
    if (this.fundS.link.bank) newMethods = this.allMethodsSub$.getValue()?.filter((method: IPaymentMethod) => !!method?.bankAccountDto) || [];
    if (this.fundS.link.card) newMethods = this.allMethodsSub$.getValue()?.filter((method: IPaymentMethod) => !!method?.cardDto) || [];
    this.methodsSub$.next(newMethods);
  }

  // === PAYMENTS =============================================================
  dataPayments$ = new BehaviorSubject<IResponse<IBalanceOperation> | null>(null);
  payments$ = new BehaviorSubject<Array<IBalanceOperation>>([]);

  // !!! for page payments/balances
  getPaymentsCompetition(settingsRequest: ClassSettingsRequest, competitionId: string): Observable<IResponse<IBalanceOperation> | null> {
    const request = { ...settingsRequest };
    if (request.status === undefined) {
      delete request.status;
    }
    return this.mainS.getPaymentsCompetition(settingsRequest, competitionId)
      .pipe(
        tap((res: IResponse<IBalanceOperation> | null) => {
          this.dataPayments$.next(res);
          this.payments$.next(res?.content || []);
        }),
      );
  }

  // !!! for page payments/balances/all
  getPayments(settingsRequest: ClassSettingsRequest): Observable<IResponse<IBalanceOperation> | null> {
    const request = { ...settingsRequest };
  
    // Remove undefined or unnecessary parameters
    if (request.status === undefined) {
      delete request.status;
    }
  
    // Pass the `page` and other pagination parameters dynamically
    return this.mainS.getPayments(request)
      .pipe(
        tap((res: IResponse<IBalanceOperation> | null) => {
          // Append the new data for the current page to the existing data
          const currentData = this.payments$.value || [];
          const newData = res?.content || [];
  
          // Update the payments observable with the combined data
          this.payments$.next([...currentData, ...newData]);
  
          // Update the full response data
          this.dataPayments$.next(res);
        }),
      );
  }


  // getPayments(settingsRequest: ClassSettingsRequest): Observable<IResponse<IBalanceOperation> | null> {
  //   const request = { ...settingsRequest };
  //   if (request.status === undefined) {
  //     delete request.status;
  //   }
    
  //   return this.mainS.getPayments(settingsRequest)
  //     .pipe(
  //       tap((res: IResponse<IBalanceOperation> | null) => {
  //         this.dataPayments$.next(res);
  //         this.payments$.next(res?.content || []);
  //       }),
  //     );
  // }

  // === for page Assignor Fees ===========================================
  dataArrFakeAssignorFees$ = new BehaviorSubject<IResponse<IAssignorFees> | null>(null);
  fakeAssignorFees$ = new BehaviorSubject<Array<IAssignorFees>>([]);

  getFakeAssignorFees(settingsRequest: ClassSettingsRequest): Observable<IResponse<IAssignorFees> | null> {
    const arrGroupAssignorFees: Array<IAssignorFees> = [this.forTestS.fakeAssignorFees, this.forTestS.fakeAssignorFees, this.forTestS.fakeAssignorFees, this.forTestS.fakeAssignorFees, this.forTestS.fakeAssignorFees];
    const dataTest: IResponse<IAssignorFees> = { content: arrGroupAssignorFees };
    return of(dataTest)
      .pipe(
        tap((res) => {
          this.dataArrFakeAssignorFees$.next(res);
          this.fakeAssignorFees$.next(res?.content || []);
        }),
      );
  }

  // === Payers ============================================
  dataFakePayers$ = new BehaviorSubject<IResponse<IPayer> | null>(null);
  arrFakePayers$ = new BehaviorSubject<Array<IPayer>>([]);

  getFakePayers(settingsRequest?: ClassSettingsRequest, competitionId?: string): Observable<IResponse<IPayer> | null> {
    const arrFakePayer: Array<IPayer> = [{ ...this.forTestS.fakePayer, id: '222' }, {
      ...this.forTestS.fakePayer,
      payoutFormat: 'Check',
      id: '333',
    }, this.forTestS.fakePayer, this.forTestS.fakePayer];
    const dataTest: IResponse<IPayer> = { content: arrFakePayer };
    return of(dataTest)
      .pipe(
        tap((res) => {
          this.dataFakePayers$.next(res);
          this.arrFakePayers$.next(res?.content || []);
        }),
      );
  }

  // === AUTO TOP UP ====================================
  topUpSub$ = new BehaviorSubject<ITopup | undefined>(undefined);

  openPopupAutoToPup(arrPaymentMethods?: Array<IPaymentMethod>): void {
    const configDataPopup = {
      // panelClass: 'panelClass_popupAutoTopup',
      width: '436px',
      minWidth: '436px',
      maxWidth: '436px',
      data: { topup: this.topUpSub$.getValue(), arrPaymentMethods: arrPaymentMethods || this.allMethodsSub$.getValue() },
    };
    this.dialog.open(PopupAutoTopupComponent, configDataPopup).afterClosed().toPromise().then((res?: ITopup) => {
      console.log('openPopupAutoToPup :', res);
      if (res) this.topUpSub$.next(res);
    });
  }

  // === POPUP ================================================
  openPopupEditCharts(arrForAnalytics: Array<ITestArrForAnalytics>): Promise<any> {
    const dataPopup: MatDialogConfig = { width: '425px', minWidth: '425px', maxWidth: '425px', data: { arrForAnalytics } };
    return this.dialog.open(PopupEditChartsComponent, dataPopup).afterClosed().toPromise();
  }

  // === FROM 2 VERSION ========================================
  payMethodsSub$ = new BehaviorSubject<Array<IPaymentMethod>>([]);

  addPayMethod(payMethod: IPaymentMethod): Array<IPaymentMethod> {
    this.payMethodsSub$.next([...this.payMethodsSub$.getValue(), payMethod]);
    return this.payMethodsSub$.getValue();
  }

  public _settings = new BehaviorSubject<ClassSettingsRequest | null>(new ClassSettingsRequest());
  settings$ = this._settings.asObservable();

  private _isFundsEnabled = new BehaviorSubject<boolean>(false);
  isFundsEnabled$ = this._isFundsEnabled.asObservable();

  competitionChanged = new EventEmitter<ClassCompetition>();

  setSettings(settings: ClassSettingsRequest) {
    this._settings.next(settings);
    const isEnabled = this.isAddFundsEnabled(settings);
    this._isFundsEnabled.next(isEnabled);
  }

  onCompetitionChange(selectedCompetition: ClassCompetition): void {
    this.competitionChanged.emit(selectedCompetition);
    const settings = this._settings.getValue();
    if (settings) {
      settings.competitions = selectedCompetition.id;
      this.setSettings(settings);
      const isEnabled = this.isAddFundsEnabled(settings);
      this._isFundsEnabled.next(isEnabled);
    }
  }

  isAddFundsEnabled(settings: ClassSettingsRequest): boolean {
    const competitions = settings.competitions;
    const isEnabled = competitions !== 'all' && competitions != undefined;
    // console.log('isAddFundsEnabled:', isEnabled, 'competitions:', competitions);
    return isEnabled;
  }

}
