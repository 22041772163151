<ng-container *ngIf="showAddNewPayment">
  <app-add-new-payment class="newpaymentAuto"
    [competitionId]="getCompetitionId()"
    (closeComponent)="handleAddNewPaymentClose()">
  </app-add-new-payment>
</ng-container>
<ng-container *ngIf="!showAddNewPayment">
<div *ngIf='{desktop: (deviceS.isDesktop$|async),
  mobile: (deviceS.isMobile$|async),
  } as obj' class='o-wrapPage wrapperAutoTopup wrapperPaymentsBalanceAutoTopup'  wrapPage [widthPage]='widthPage' cdkScrollable dataTable>
  
  <div class="topup-header">
    <svgAndText text="Auto Top-Up" leftSvgName="arrow_squareLeft&24" typeText="text1-blueDark" (leftSvgEmit)="goBack()">
    </svgAndText>
  
    <div *ngIf="obj.desktop && form.get('bufferAmount')?.value && form.get('daySelection')?.value && form.get('paymentMethod')?.value" style="display: flex; justify-content:flex-end">
      <btnWrap>
        <ng-container *ngIf="isEnabled; else reActivateButton">
          <btn *ngIf="showButtons" text='Cancel Top-Up' 
               [btnHeight]='(deviceS.isDesktop$|async)?40:44' 
               btnWidth='133' 
               bcg='red' 
               (click)='cancelTopUp()'>
          </btn>
        </ng-container>
        <ng-template #reActivateButton>
          <btn text='Reactivate' 
               [btnHeight]='(deviceS.isDesktop$|async)?40:44' 
               btnWidth='133' 
               bcg='newGreen' 
               (click)='saveChanges()'>
          </btn>
        </ng-template>
        <!-- *ngIf="form.get('bufferAmount')?.value && form.get('daySelection')?.value" -->
        <btn 
        *ngIf="form.get('bufferAmount')?.value && form.get('daySelection')?.value && form.get('paymentMethod')?.value &&isSaveChangesVisible && isEnabled"
        text='Save Changes' [spinner]='reqPending' [btnHeight]='(deviceS.isDesktop$|async)?40:44' btnWidth='133' bcg='blueDark'
        (click)='saveChanges()'></btn>
      </btnWrap>
    </div>
  </div>

    <div class="autopopupFilters">

    <div class="payment-method-container">
    <div style="display: flex; flex-direction: row;">
      <label class="selectDay__title">Top-Up Enabled</label>
      <div class="tooltip-container">
        <img src="assets/question/question_grey&16.svg" alt="tooltip">
        <div class="tooltip-content">Choose the weekday for automatically triggering a top-up.</div>
      </div>
    </div>
    <app-dropdown 
      [ngModel]="selectedDay" 
      [array]="daysOfWeek" 
      (changeValObj)="onDayChange($event)"
      [placeholder]="'Select Day'"
      [needSortByName]="false" >
    </app-dropdown>
    </div>

    <div class="payment-method-container">
      <div style="display: flex; flex-direction: row;">
        <label class="selectDay__title">Select Payment Method</label>
        <div class="tooltip-container">
          <img src="assets/question/question_grey&16.svg" alt="tooltip">
          <div class="tooltip-content">Pick an existing payment method or add a new one to automatically debit the top-up amount.</div>
        </div>
      </div>
      <app-dropdown [ngModel]="selectedPaymentMethod" 
      [array]="paymentMethodsForDropdown" 
      (changeValObj)="onPaymentMethodChange($event)"
      [needSortByName]="false"
      [placeholder]="'Select Payment Method'">
      </app-dropdown>
    </div>
      
<div class="buffer-amount-container" [formGroup]="form">
  <div style="display: flex; flex-direction: row;">
    <label class="selectDay__title">Buffer</label>
    <div class="tooltip-container">
      <img src="assets/question/question_grey&16.svg" alt="tooltip">
      <div class="tooltip-content">Set an optional buffer amount to add to the top-up, ensuring funds are available for unexpected assignments or changes.</div>
    </div>
  </div>
  <inputCtrl 
    formControlName="bufferAmount" 
    [showDollar]="true" 
    [isNumber]="true"  
    [placeholder]="'(e.g., $500)'">
  </inputCtrl>
</div>

</div>


<div class="autotopupButtons" *ngIf="obj.mobile && form.get('bufferAmount')?.value && form.get('daySelection')?.value && form.get('paymentMethod')?.value">
  <btnWrap>
    <ng-container *ngIf="isEnabled; else reActivateButton">
      <btn *ngIf="showButtons" text='Cancel Top-Up' 
           [btnHeight]='(deviceS.isDesktop$|async)?40:44' 
           bcg='red' 
           (click)='cancelTopUp()'>
      </btn>
    </ng-container>
    <ng-template #reActivateButton>
      <btn text='Reactivate' 
           [btnHeight]='(deviceS.isDesktop$|async)?40:44' 
           bcg='newGreen' 
           (click)='saveChanges()'>
      </btn>
    </ng-template>
    <btn 
    *ngIf="form.get('bufferAmount')?.value && form.get('daySelection')?.value && form.get('paymentMethod')?.value &&isSaveChangesVisible && isEnabled"
    text='Save Changes' [spinner]='reqPending' [btnHeight]='(deviceS.isDesktop$|async)?40:44' bcg='blueDark'
    (click)='saveChanges()'></btn>
  </btnWrap>
</div>

  <div class="wrapperLinkPage" *ngIf="obj.desktop">
    <div class="wrapperLinkPage__item" 
         [class.wrapperLinkPage--activeItem]="activeTab === 'schedule'" 
         (click)="setActiveTab('schedule')">
      Top-Up Schedule
    </div>
    <div class="wrapperLinkPage__item" 
         [class.wrapperLinkPage--activeItem]="activeTab === 'history'" 
         (click)="setActiveTab('history')">
      Top-Up History
    </div>
  </div>

  <div class="topupDisclaimer" *ngIf="activeTab === 'schedule'">The Top-Up schedule shows projected payments initiated by Notch based on the current competition game schedule. We recommend including a buffer equal to 100% of the competition's typical 7-day Pending Outflows to protect against bank processing delays.</div>

  <div class="payment-method-container historyDropdown" *ngIf="deviceS.isMobile$ | async">
    <div class="selected-tab">
      {{ activeTabMobile.titleCase }}
    </div>
    <app-dropdown 
      [ngModel]="activeTabMobile" 
      [array]="tabOptions" 
      (changeValObj)="setActiveTabMobile($event)"
      [placeholder]="placeholderText"
      [needSortByName]="false">
    </app-dropdown>
  </div>
  <div *ngIf="showStripeForm" class="stripe-popup-overlay">
    <div class="stripe-popup-content">
      <stripePopup textApply='Save' textCancel='Cancel' [isCreatePayMethod]='true'
        (createPayMethod)='handlePaymentMethodCreated($event)' (cancel)='handleCancel()'
        [competitionId]='getCompetitionId()'>
      </stripePopup>
    </div>
  </div>
  

  <ng-container *ngIf="activeTab === 'schedule'">
  <div class="topup-data-table">
    <div class='t-wrapperTable'>
      <div class="st-table-header hide-on-mobile">
        <div class="st-table-header__ceil" style="width: 155px;">Top-up Date</div>
        <div class="st-table-header__ceil" style="width: 225px;">
          <div style="display: flex; flex-direction: row;">
            <label class="selectDay__title">Exp. 7-day Outflow</label>
            <div class="tooltip-container">
              <img src="assets/question/question_grey&16.svg" alt="tooltip">
              <div class="tooltip-content">Pending Outflows are the projected payments for future games whether assigned or not.</div>
            </div>
          </div>
        </div>
        <div class="st-table-header__ceil" style="width: 165px;">
          <div style="display: flex; flex-direction: row;">
            <label class="selectDay__title"> Exp. Balance </label>
            <div class="tooltip-container">
              <img src="assets/question/question_grey&16.svg" alt="tooltip">
              <div class="tooltip-content">Your expected balance including any pending inflows due to arrive by the top-up date </div>
            </div>
          </div>
        </div>
        <!-- <div class="st-table-header__ceil" style="width: 140px;">Buffer</div> -->
        <div class="st-table-header__ceil" style="width: 220px;">
          <div style="display: flex; flex-direction: row;">
            <label class="selectDay__title">Exp. Net Payment-In </label>
            <div class="tooltip-container">
              <img src="assets/question/question_grey&16.svg" alt="tooltip">
              <div class="tooltip-content">Shows the expected total amount of your top-up based on the 7 day outflow, balance and buffer amount. </div>
            </div>
          </div>
        </div>
      </div>
      
      <div  *ngIf="!isLoading" class='t-table-v2'>
        <div *ngIf="isEnabled" >
        <ng-container *ngFor='let item of topupData'>
          <div class='t-table-v2__item'>
            <div class='t-table-v2__item__wrapCeil mobile-row' style="width: 200px;">
              <div class="label" *ngIf="obj.mobile">Top-up Date</div>
              <div class="value"> {{item.topupDate  | date }}</div>
            </div>
            <div class='t-table-v2__item__wrapCeil mobile-row' style="width: 200px;">
              <div class="label" *ngIf="obj.mobile">7-day Outflow</div>
              <div class="value"> {{item.outflow | currency}}</div>
            </div>
            <div class='t-table-v2__item__wrapCeil mobile-row' style="width: 200px;">
              <div class="label" *ngIf="obj.mobile">Balance</div>
              <div class="value">{{item.balance | currency}}</div>
            </div>
            <!-- <div class='t-table-v2__item__wrapCeil mobile-row' style="width: 140px;">
              <div class="label" *ngIf="obj.mobile">Buffer </div>
              <div class="value">{{item.buffer | currency}}</div>
            </div> -->
            <div class='t-table-v2__item__wrapCeil mobile-row' style="width: 180px;">
              <div class="label" *ngIf="obj.mobile">Net Payment-In </div>
              <div class="value">{{item.netPayin | currency}} </div>
            </div>
          </div>
        </ng-container>
        </div>
    
        <div class="t-table-v2-tableEmpty" *ngIf="topupData.length === 0 || !isEnabled">
          Your expected top-up schedule will appear here once you have enabled an auto top-up.
        </div>
      </div>

      <div *ngIf="isLoading" class="spinner-container">
        <spinner></spinner>
      </div>
    </div>
  </div>
  </ng-container>

  <ng-container *ngIf="activeTab === 'history'">
  <div class="topup-data-table">
    <div class='t-wrapperTable'>
      <div class="st-table-header hide-on-mobile">
        <div class="st-table-header__ceil" style="width: 140px;">Top-up Date</div>
        <div class="st-table-header__ceil" style="width: 160px;">
          <div style="display: flex; flex-direction: row;">
            <label class="selectDay__title">Top-Up Amount</label>
            <div class="tooltip-container">
              <img src="assets/question/question_grey&16.svg" alt="tooltip">
              <div class="tooltip-content">Shows the total top-up amount based on the 7 day outflow, any remaining balance and your buffer amount.</div>
            </div>
          </div>
        </div>
        <div class="st-table-header__ceil" style="width: 135px;">Fees</div>
        <div class="st-table-header__ceil" style="width: 140px;">Payment Method</div>
        <div class="st-table-header__ceil" style="width: 170px;">Status</div>
      </div>
      
      <div class='t-table-v2'>
        <ng-container *ngFor='let item of topupHistory'>
          <div class='t-table-v2__item'>
            <div class='t-table-v2__item__wrapCeil  mobile-row' style="width: 140px;">
              <div class="label" *ngIf="obj.mobile">Top-up Date</div>
              <div class="value">{{ item.createdAt | date: 'MMM d, yyyy' }}</div>
            </div>
            <div class='t-table-v2__item__wrapCeil  mobile-row' style="width: 160px;">
              <div class="label" *ngIf="obj.mobile">Top-Up Amount</div>
              <div class="value">{{ item.totalAmount/100 | currency }}</div>
            </div>
            <div class='t-table-v2__item__wrapCeil  mobile-row' style="width: 135px;">
              <div class="label" *ngIf="obj.mobile">Fees</div>
              <div class="value">{{ item.fee/100 | currency }}</div>
            </div>
            <div class='t-table-v2__item__wrapCeil  mobile-row' style="width: 140px;">
              <div class="label" *ngIf="obj.mobile">Payment Method</div>
              <div class="value">{{ item.paymentMethod || 'N/A' }}</div>
            </div>
            <div class='t-table-v2__item__wrapCeilStatus  mobile-row' style="width: max-content;"
            [ngClass]="'wrapperStatus--' + item.status">{{ item.status | titlecase}}</div>
          </div>
        </ng-container>
    
        <div class="t-table-v2-tableEmpty" *ngIf="topupHistory.length === 0">
          Your Auto Top-Up history will appear here
        </div>
      </div>
    </div>
  </div>
  </ng-container>
</div>
</ng-container>