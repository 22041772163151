import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { HelperClass } from '@classes/Helper-Classes';
import { SvgAndTextComponent } from '@components/__svg_img/svg-and-text/svg-and-text.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { BtnComponent } from '@components/btn/btn.component';
import { CompetitionService } from '@app/dir_group_assignor/competitions/competition.service';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import {
  ClassCompetition,
  ClassPayoutFormat,
  TCompetitionTypeDrop,
  TGameTypeDrop,
  TSportDrop,
} from '@app/dir_group_assignor/competitions/ClassCompetition';
import { MeService } from '@services/me.service';
import {
  CompetitionsNavigationComponent,
} from '@app/dir_group_assignor/competitions/helperComponentsCompetitions/competitions-navigation/competitions-navigation.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { THttpMethod } from '@services/base-api';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { ClassDrop, ClassYesNo, getArrayDropFromNumber } from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { CheckActiveService } from '@app/dir_group_assignor/competitions/services/checkActiveService';
import { EnableSelfRequestService } from '@app/dir_group_assignor/competitions/services/self-request-state.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApiCompetitionService } from '@app/dir_group_assignor/competitions/api-competition.service';

interface ClassCompetitionsGeneralInfo {
  competitionName: FormControl<string>;
  sportsDrop: FormControl<Array<TSportDrop>>;
  competitionTypeDrop: FormControl<TCompetitionTypeDrop>;
  gameTypeDrop: FormControl<TGameTypeDrop>;
  enableSelfRequest: FormControl<ClassYesNo>;
  selfApplyLimit: FormControl<ClassDrop>;
  maxCrewSizeDrop: FormControl<ClassDrop>;
  payoutFormatDrop: FormControl<ClassPayoutFormat>;
}

@UntilDestroy()
@Component({
  selector: 'competitionsGeneralInfo',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FieldComponent, InputCtrlComponent, HeadTitleComponent, SvgAndTextComponent, BtnWrapComponent, BtnComponent, DropFormCtrlComponent, CompetitionsNavigationComponent, MatProgressSpinnerModule, DropdownComponent],
  templateUrl: './competitions-general-info.component.html',
  styleUrls: ['./competitions-general-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompetitionsGeneralInfoComponent extends HelperClass implements OnInit {
  form!: FormGroup<ClassCompetitionsGeneralInfo>;

  private enableSelfRequestService = inject(EnableSelfRequestService);
  private isDisableLimitSelfRequest: boolean = true;

  private isSave: boolean = false; // Artur comment => Флаг для отмены повторного запроса на получение данных, не удалять
  isSelfRequest: boolean = true; // Artur comment => как будет закончен бэк убрать


  constructor(
    private formBuilder: UntypedFormBuilder,
    public competitionS: CompetitionService,
    private apiCompetitionS: ApiCompetitionService,
    private meS: MeService,
    private checkActiveGamesService: CheckActiveService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    this.createForm();
  }

  ngOnInit() {
    this.subscribeToCompetition();
  }

  subscribeToCompetition(): void {
    this.competitionS.competition$.pipe(untilDestroyed(this)).subscribe((competition) => {
      if (!competition?.id) {
        this.selfRequestFormProcess();
        this.cd.detectChanges();
        return;
      }
      //  часть логики по установлению первоначального значения надо будет перенести сюда может возникать проблема при установке там поле просто надо будет сделать enable
      this.selfRequestLimitProcess(competition);
    });
  }

  private selfRequestLimitProcess(competition: ClassCompetition) {
    const competitionId = this.competitionS?.competition?.id!;
    if (!this.isSave) {
      this.apiCompetitionS.getSelfLimited(competitionId).pipe(untilDestroyed(this)).subscribe((res) => {
        if (res) {
          let modifySelfApplyLimit;
          if (res.selfApplyLimit.toString() === '1') {
            modifySelfApplyLimit = `${res.selfApplyLimit} request per day`;
          } else if (Number(res.selfApplyLimit) > 6) {
            modifySelfApplyLimit = 'UNLIMITED';
          } else {
            modifySelfApplyLimit = `${res.selfApplyLimit} requests per day`;
          }
          const selfRequestLimitSubject: ClassDrop = {
            upperCase: modifySelfApplyLimit,
          };
          if (Number(res.selfApplyLimit) === 0) {
            this.ctrl.enableSelfRequest.patchValue(new ClassYesNo(false));
          } else {
            this.ctrl.enableSelfRequest.patchValue(new ClassYesNo(true));
            this.ctrl.selfApplyLimit.enable();
          }
          this.enableSelfRequestService.selfRequestLimitSubject = selfRequestLimitSubject;
          competition.selfApplyLimit = selfRequestLimitSubject;
          this.ctrl.selfApplyLimit.patchValue(competition.selfApplyLimit);
          this.form.get('selfApplyLimit')?.patchValue(competition.selfApplyLimit);
        } else {
          this.ctrl.enableSelfRequest.patchValue(new ClassYesNo(false));
        }
        this.selfRequestFormProcess();
        this.cd.detectChanges();
      });
    }
  }

  // === FORM ==============
  private createForm(): void {
    const competition = this.competitionS.competition; // !!! если нет competition?.id, то это страница setup-competition
    this.form = this.formBuilder.group({
      competitionName: [competition.competitionName, [Validators.required]],
      sportsDrop: [competition.sportsDrop, [Validators.required]],
      competitionTypeDrop: [competition.competitionTypeDrop, [Validators.required]],
      gameTypeDrop: [competition.gameTypeDrop, [Validators.required]],
      maxCrewSizeDrop: [competition.maxCrewSizeDrop || getArrayDropFromNumber(4)],
      payoutFormatDrop: [competition.payoutFormatDrop, [Validators.required]],
      enableSelfRequest: [{ value: competition.enableSelfRequest }, [Validators.required]],
      selfApplyLimit: [{ value: '', disabled: this.isDisableLimitSelfRequest }],
    });
  }

  get ctrl(): ClassCompetitionsGeneralInfo {
    return this.form?.controls;
  }

  // === competitions Btns Emit =========================
  async competitionsBtnsEmit(isNext: boolean): Promise<void> {
    const competitionId = this.competitionS?.competition?.id;
    const isActiveModal = await this.checkActiveGamesService.checkActive(competitionId);
    // Флаг для отмены повторного запроса на получение данных, не удалять
    this.isSave = true;
    if (isActiveModal) {
      if (!competitionId) this.methodCompetition(isNext, 'post'); // create
      if (competitionId) this.methodCompetition(isNext, 'put'); // update
    }
  }

  methodCompetition(isNext = false, httpMethod: THttpMethod): void {
    if (this.startRequest()) return;
    const sendObj: ClassCompetition = {
      competitionName: this.ctrl.competitionName?.value,
      sports: this.ctrl.sportsDrop?.value?.map((el) => el.upperCase).toString(),
      competitionType: this.ctrl.competitionTypeDrop?.value?.upperCase,
      maxCrewSize: +this.ctrl.maxCrewSizeDrop?.value?.upperCase!,
      gameType: this.ctrl.gameTypeDrop?.value?.upperCase,
      payoutFormat: this.ctrl.payoutFormatDrop?.value?.upperCase,
      enableSelfRequest: this.ctrl.enableSelfRequest.value,
      selfApplyLimit: this.ctrl.selfApplyLimit.value,
    };

    const competitionId = this.competitionS.competition?.id;
    if (sendObj.selfApplyLimit) {
      this.enableSelfRequestService.selfRequestLimitSubject = sendObj.selfApplyLimit;

    }
    if (sendObj.enableSelfRequest) {
      this.enableSelfRequestService.enableSelfRequestSubject = sendObj.enableSelfRequest.titleCase === 'Yes';
    }
    if (competitionId) {
      sendObj.id = competitionId;
    }
    if (this.form.get('enableSelfRequest')?.value.titleCase === 'No') {
      sendObj.selfApplyLimit = {
        titleCase: '0',
        upperCase: '0',
      };
    }
    this.apiCompetitionS.methodCompetition(sendObj, httpMethod).toPromise()
      .then((res?: ClassCompetition) => {
        if (!res?.id) return;
        this.apiCompetitionS.updateSelfLimit(sendObj.selfApplyLimit?.titleCase?.split(' ')[0] || sendObj.selfApplyLimit?.upperCase?.toLowerCase().split(' ')[0],
          res.id, this.meS.meId, this.meS.meRole).pipe(untilDestroyed(this)).subscribe(() => {
          this.competitionS.updateCompetition(res, `${httpMethod} competition`);
          isNext ? this.competitionS.nextStep('about') : this.competitionS.goToDashboard();
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => this.endRequest());
  }

  private selfRequestFormProcess() {
    this.form.get('enableSelfRequest')?.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      const enabled = value?.titleCase === 'Yes';
      this.isDisableLimitSelfRequest = enabled;
      this.enableSelfRequestService.enableSelfRequestSubject = enabled;
      const selfApplyLimit = this.form.get('selfApplyLimit');
      if (enabled) {
        selfApplyLimit?.enable();
      } else {
        selfApplyLimit?.disable();
        this.ctrl.selfApplyLimit.patchValue({ upperCase: '' });
      }
    });
    this.form.get('selfApplyLimit')?.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      const limit = value as ClassDrop;
      if (limit) {
        this.enableSelfRequestService.selfRequestLimitSubject = limit;
      }
    });
  }
}
