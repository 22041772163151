import { Injectable } from '@angular/core';
import moment from 'moment';
import { ITime, THours, TMeridiem, TMinutes } from '@components/date-time/date-time.service';
import { ITimezone, TTypeTimezone } from '@classes/geo';

export const arrDaysOfWeek = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

// !!! from https://angular.io/api/common/DatePipe
export enum EValuesForAngularPipe {
  short = 'M/d/yy, h:mm a', // 6/15/15, 9:03 AM
  medium = 'MMM d, y, h:mm:ss a', // Jun 15, 2015, 9:03:01 AM
  long = 'MMMM d, y, h:mm:ss a z', // June 15, 2015 at 9:03:01 AM GMT+1
  full = 'EEEE, MMMM d, y, h:mm:ss a zzzz', // Monday, June 15, 2015 at 9:03:01 AM GMT+01:00
  shortDate = 'M/d/yy', // 6/15/15
  mediumDate = 'MMM d, y', // Jun 15, 2015
  longDate = 'MMMM d, y', // June 15, 2015
  fullDate = 'EEEE, MMMM d, y', // Monday, June 15, 2015
  shortTime = 'h:mm a', // 9:03 AM
  mediumTime = 'h:mm:ss a', // 9:03:01 AM
  longTime = 'h:mm:ss a z', // 9:03:01 AM GMT+1
  fullTime = 'h:mm:ss a zzzz', // 9:03:01 AM GMT+01:00
}

@Injectable({ providedIn: 'root' })
export class CustomDatesService {
  format: 'YYYY-MM-DDTHH:mm:ss' | 'YYYY-MM-DD' = 'YYYY-MM-DDTHH:mm:ss';

  get todayDate(): Date {
    return new Date();
  }

  get todayShortFormatDate(): string {
    return this.formatDate('YYYY-MM-DD', this.todayDate);
  }

  get nowFormatDate(): string {
    return this.formatDate('YYYY-MM-DD:mm:ss.SSS', this.todayDate); // 'YYYY-MM-DD:mm:ss.SSS' "2023-08-29:46:11.119"
  }

  get todayMidnightFormatDate(): string {
    return this.formatDate('YYYY-MM-DD', this.todayDate) + 'T00:00:00'; // 'YYYY-MM-DD:mm:ss.SSS' "2023-08-29:00:00.00"
  }
  get todayMidnightFormatDate_UTC(): string {
    // console.log('todayMidnightFormatDate :', this.todayMidnightFormatDate)
    const convertDate = this.convertDate(this.todayMidnightFormatDate, 'User', 'Utc');
    // console.log('convertDate :', convertDate)
    const result = this.formatDate(this.format, convertDate);
    // console.log('result :', result)
    return result;
    // return this.formatDate(this.format, convertDate);
  }

  get year(): number {
    return this.todayDate.getFullYear();
  }

  get month(): number {
    return this.todayDate.getMonth();
  }

  get todayDay(): string {
    return this.getDay(this.todayDate);
  }

  get yesterdayDate(): Date {
    const yesterday = new Date(this.todayDate);
    yesterday.setDate(this.todayDate.getDate() - 1);
    return yesterday;
  }

  readonly yesterdayDay: string = this.getDay(this.yesterdayDate);
  // readonly yesterdayMidnightFormatDate: string = this.formatDate('YYYY-MM-DD', this.yesterdayDate) + 'T00:00:00'; // 'YYYY-MM-DD:mm:ss.SSS' "2023-08-29:00:00.00"
  get yesterdayMidnightFormatDate(): string {
    const yesterday = new Date(this.todayDate);
    yesterday.setDate(this.todayDate.getDate() - 1);
    return this.formatDate('YYYY-MM-DD', yesterday) + 'T00:00:00'; // 'YYYY-MM-DD:mm:ss.SSS' "2023-08-28:00:00.00"
  }
  get yesterdayMidnightFormatDate_UTC(): string {
    // console.log('yesterdayMidnightFormatDate :', this.yesterdayMidnightFormatDate)
    const convertDate = this.convertDate(this.yesterdayMidnightFormatDate, 'User', 'Utc');
    // console.log('convertDate :', convertDate)
    // return this.formatDate(this.format, convertDate);
    const result = this.formatDate(this.format, convertDate);
    // console.log('result :', result)
    return result;
  }

  get beforeYesterdayDate(): Date {
    const beforeYesterday = new Date(this.todayDate);
    beforeYesterday.setDate(this.todayDate.getDate() - 2);
    return beforeYesterday;
  }


  get beforeYesterdayMidnightFormatDate(): string {
    return this.formatDate('YYYY-MM-DD', this.beforeYesterdayDate) + 'T00:00:00';
  }

  get beforeYesterdayDay(): string {
    return this.getDay(this.beforeYesterdayDate);
  }
  //  readonly beforeYesterdayMidnightFormatDate: string = this.formatDate('YYYY-MM-DD', this.beforeYesterdayDate) + 'T00:00:00'; // 'YYYY-MM-DD:mm:ss.SSS' "2023-08-29:00:00.00"



  get firstDayLastMonth(): Date {
    return new Date(this.year, this.month - 1, 1);
  }

  get lastDayLastMonth(): Date {
    return new Date(this.year, this.month, 0);
  }

  get firstDayMonth(): Date {
    return new Date(this.year, this.month, 1);
  }

  get lastDayMonth(): Date {
    return new Date(this.year, this.month + 1, 0);
  }

  // get firstDayQuarterMonth(): Date {
  //   return new Date(this.year, this.month - 3, 1);
  // }

  get firstDayQuarterMonth(): Date {
    const currentMonth = this.todayDate.getMonth(); // 0-based (January = 0, February = 1, etc.)
    const currentYear = this.year;
  
    if (currentMonth < 3) {
      // Q1: January to March
      return new Date(currentYear, 0, 1); // January 1st
    } else if (currentMonth < 6) {
      // Q2: April to June
      return new Date(currentYear, 3, 1); // April 1st
    } else if (currentMonth < 9) {
      // Q3: July to September
      return new Date(currentYear, 6, 1); // July 1st
    } else {
      // Q4: October to December
      return new Date(currentYear, 9, 1); // October 1st
    }
  }

  // get dayYearAgo(): Date {
  //   return new Date(this.year - 1, this.month, +this.todayDay);
  // }

  get dayYearAgo(): Date {
    return new Date(this.year, 0, 1);
  }

  get firstDayWeek(): Date {
    return this.getDayWeek();
  }

  get lastDayWeek(): Date {
    return this.getDayWeek(false);
  }

  // readonly tomorrowDate: Date = new Date(this.year, this.month, +this.todayDay + 1);

  get tomorrowDate(): Date {
    const today = new Date(); // Get today's date
    const year = today.getFullYear();
    const month = today.getMonth();
    const day = today.getDate() + 1; // Add 1 to the current day to get tomorrow
    return new Date(year, month, day);
  }

  get tomorrowMidnightFormatDate(): string {
    return this.formatDate('YYYY-MM-DD', this.tomorrowDate) + 'T00:00:00'; // 'YYYY-MM-DD:mm:ss.SSS' "2023-08-30:00:00.00"
  }

  get afterTomorrowDate(): Date {
    return new Date(this.year, this.month, +this.todayDay + 2);
  }


  get afterTomorrowMidnightFormatDate(): string {
    return this.formatDate('YYYY-MM-DD', this.afterTomorrowDate) + 'T00:00:00';
  }



  utcTimezone: ITimezone = { abbrev: 'UTC', name: 'Coordinated Universal Time', id: 'UTC', value: 0 };
  oneHour = 3600000;

  constructor() {
    // console.log('firstDayLastMonth :', this.formatDate('YYYY-MM-DD',this.firstDayLastMonth))
    // console.log('lastDayLastMonth :', this.formatDate('YYYY-MM-DD',this.lastDayLastMonth))
    // console.log('firstDayMonth :', this.formatDate('YYYY-MM-DD',this.firstDayMonth))
    // console.log('lastDayMonth :', this.formatDate('YYYY-MM-DD',this.lastDayMonth))

    // this.formatDate('YYYY-MM-DDTHH:mm:ss.SSS', this.firstDayMonth)
  }


  // !!! учитывает таймзону юзера
  // Example format 'YYYY-MM-DDTHH:mm:ss.SSS' || 'YYYY-MM-DD' // return "2022-07-11T12:34:04.000"
  formatDate(format: string, date: Date | string = this.todayDate): string {
    const d = this.convertToDate(date);
    // const format = EValuesForAngularPipe[strFormat] ? EValuesForAngularPipe[strFormat] : strFormat;
    return moment(d).format(format);
  }

  // !!! возвращает дату минус или плюс (fewDays) количество дней от текущей даты. возвращает в UTC
  getFormatDatePlusFewDays(format: string, fewDays: number): string {
    let date = new Date(this.year, this.month, +this.todayDay + fewDays);
    return this.formatDate(format, date);
  }

  getDay(date: Date): string {
    // let day = this.todayDate.getUTCDate().toString();
    let day = date.getDate().toString();
    if (day.length === 1) day = '0' + day;
    return day;
  }

  // Первый/последний день недели
  getDayWeek(isFirst = true): Date {
    let today = new Date();
    const first = today.getUTCDate() - today.getUTCDay() + 1;
    const last = first + 6;

    if (isFirst) {
      return new Date(today.setDate(first));
    } else {
      return new Date(today.setDate(last));
    }
  }

  convertToDate(date: Date | string): Date {
    let d: Date;
    if (typeof date === 'string') {
      d = new Date(date);
    } else if (date) {
      // d = date;
      d = new Date(date); /////////
    } else {
      d = new Date();
    }
    return d;
  }

  // =============================
  getTimezoneOffset(date?: Date): number { // если дату не передать, то получим по таймзоне юзера
    if (date) {
      return date.getTimezoneOffset() / 60;
    } else {
      return new Date().getTimezoneOffset() / 60;
    }
  }

  getMillisecondTimezoneOffset(date?: Date): number { // если дату не передать, то получим по таймзоне юзера
    return this.getTimezoneOffset(date) * 3600000;
  }

  // сбросить дату на полночь
  getDateToMidnight(date?: Date): Date { // если дату не передать, то получим по таймзоне юзера
    if (date) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    } else {
      return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    }
  }

  // return 'AM' | 'PM'
  getMeridiem(date: Date | string): TMeridiem | '' {
    if (!date) return '';
    const d: Date = this.convertToDate(date);
    return d.toLocaleString('en-US').substr(-2) as TMeridiem; // "08:58 PM"
  }

  // return "01"
  getHours(date: Date | string, meridiem?: TMeridiem): string {
    if (!date) return '';
    const d: Date = this.convertToDate(date);
    return d.toLocaleString('en-US', { hour: '2-digit', hour12: true }).split(' ')[0]; // '03'
  }

  // return '12'
  getMinutes(date: Date | string, isRoundMinutes: boolean): string {
    if (!date) return '';
    const d: Date = this.convertToDate(date);
    let minutes = d.toLocaleString('en-US', { minute: '2-digit', hour12: true }).split(' ')[0]; // '03'
    if (isRoundMinutes) minutes = this.getRoundMinutes(minutes);
    return minutes;
  }

  // return "01:02"
  getTime(date: Date): string {
    if (!date) return '';
    const d = this.convertToDate(date);
    return d.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }).split(' ')[0];
  }

  // return "01:02 AM"
  getTimeMeridiem(date: Date | string): string {
    if (!date) return '';
    const d = this.convertToDate(date);
    return d.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }); // "08:58 PM"
  }

  // прибавить к дате минуты/часы
  datePlusHoursMinutes(hours: number | string, minutes: number | string, date?: Date): Date { // если дату не передать, то получим по таймзоне юзера
    const dateMidnight = date ? this.getDateToMidnight(date) : this.getDateToMidnight(new Date()); // скинуть дату на полночь текущего дня если дату не передать
    dateMidnight.setHours(+hours, +minutes, 0, 0);
    return dateMidnight;
  }

  hours12to24(h: number | string, amPm: 'AM' | 'PM'): number {
    // console.log('hours12to24 :', h, amPm)
    if (typeof h == 'string') h = +h;
    const isPm: boolean = amPm == 'PM';
    return h == 12 ? isPm ? 12 : 0 : isPm ? h + 12 : h;
  }

  hours24to12(h: number): { hour: number, pm: boolean } {
    return {
      hour: (h + 11) % 12 + 1,
      pm: h >= 12,
    };
  }

  // прибавить к дате минуты/часы учитывая 'AM'|'PM'
  datePlusHoursMinutesWithMeridiem(dateValue: Date | string, time: ITime): Date {
    console.log('dateValue :', typeof dateValue, dateValue);
    const date = this.convertToDate(dateValue);
    console.log('date :', date);
    console.log('time :', time);
    // export interface ITime {
    //   hourValue: THours;
    //   minuteValue: TMinutes;
    //   meridiem: TMeridiem;
    // }
    // export type THours = '00' | '01' | '02' | '03' | '04' | '05' | '06' | '07' | '08' | '09' | '10' | '11' | '12'
    // export type TMinutes = '00' | '15' | '30' | '45'
    // export type TMeridiem = 'AM' | 'PM'
    let hourValue = time.hourValue;
    console.log('hourValue :', typeof hourValue, hourValue);
    if (time.meridiem == 'PM') {
      hourValue = (+hourValue + 12).toString() as THours;
      if (+hourValue == 24) hourValue = '12' as THours;
      console.log('hourValue :', typeof hourValue, hourValue);
    }
    // if (time.meridiem == 'PM') {
    //   if (+hourValue == 24) hourValue = '12' as THours;
    // }
    if (hourValue == '12' && time.meridiem == 'AM') {
      hourValue = (+hourValue - 12).toString() as THours;
      if (+hourValue == 24) hourValue = '00' as THours;
      console.log('hourValue :', typeof hourValue, hourValue);
    }
    const dateMidnight = this.getDateToMidnight(date); // скинуть дату на полночь текущего дня
    console.log('dateMidnight :', dateMidnight);
    return new Date(dateMidnight.getTime() + (+time.minuteValue * 60000) + (+hourValue * 3600000));
  }

  // 10.30 PM = 22.30 // 11.30 PM = 23.30 // 12.30 PM => 12.30
  // 10.30 AM = 10.30 // 11.30 AM = 11.30 // 12.30 AM => 00.30
  // checkHoursAfterSend(time:ITime,date: Date | string): Date {
  // checkHoursAfterSend(date: Date | string, meridiem: TMeridiem): Date {
  //   const d = this.convertToDate(date);
  //   let hourValue: THours = this.getHours(date) as THours;
  //   if ((hourValue == '12' && meridiem == 'AM')) {
  //     hourValue = '00'
  //   }
  //   if ((hourValue == '12' && meridiem == 'PM')) {
  //     hourValue = '12'
  //   }
  // }


  // с сервера поступает дата в таком виде => date: "7 Jan 2023 2:30 PM CST"
  // isRoundMinutes нужно ли округлять время к таким числам ['00', '15', '30', '45']
  convertStrFromServerToDate(str: string, isRoundMinutes: boolean = false): Date {
    const arr = str.split(' '); // ["7","Jan","2023","2:30","PM","CST"]
    let time = arr[3];
    let hours = time.split(':')[0];
    let minutes = time.split(':')[1];

    if (isRoundMinutes) minutes = this.getRoundMinutes(minutes);

    time = hours + ':' + minutes;
    let dateStr = arr[1] + ' ' + arr[0] + ' ' + arr[2] + ' ' + time + ' ' + arr[4];
    let date = new Date(dateStr);
    return date;
  }

  // с сервера поступает дата в таком виде => date: "7 Jan 2023 2:30 PM CST"
  // isRoundMinutes нужно ли округлять время к таким числам ['00', '15', '30', '45']
  // return "CST"
  convertStrFromServerToTimezone(str: string): string {
    const arr = str.split(' '); // ["7","Jan","2023","2:30","PM","CST"]
    return arr[arr.length - 1];
  }

  getTimeFromDate(date: Date | string): ITime {
    const d = this.convertToDate(date);
    const time: ITime = {
      hourValue: this.getHours(d) as THours,
      minuteValue: this.getMinutes(d, true) as TMinutes,
      meridiem: this.getMeridiem(d) as TMeridiem,
    };
    return time;
  }

  getRoundMinutes(minutes: string): string {
    let resultMinutes = minutes;
    if (+resultMinutes < 3) resultMinutes = '00';
    if (+resultMinutes >= 3 && +resultMinutes < 7) resultMinutes = '05';
    if (+resultMinutes >= 7 && +resultMinutes < 13) resultMinutes = '10';
    if (+resultMinutes >= 13 && +resultMinutes < 17) resultMinutes = '15';
    if (+resultMinutes >= 17 && +resultMinutes < 23) resultMinutes = '20';
    if (+resultMinutes >= 23 && +resultMinutes < 27) resultMinutes = '25';
    if (+resultMinutes >= 27 && +resultMinutes < 33) resultMinutes = '30';
    if (+resultMinutes >= 33 && +resultMinutes < 37) resultMinutes = '35';
    if (+resultMinutes >= 37 && +resultMinutes < 43) resultMinutes = '40';
    if (+resultMinutes >= 43 && +resultMinutes < 47) resultMinutes = '45';
    if (+resultMinutes >= 47 && +resultMinutes < 53) resultMinutes = '50';
    if (+resultMinutes >= 53 && +resultMinutes < 59) resultMinutes = '55';
    if (+resultMinutes > 59) resultMinutes = '00';
    return resultMinutes;
  }

  // === DATE TIMEZONE =========================================
  // utcTimezone: ITimezone = { abbrev: 'UTC', name: 'Coordinated Universal Time', id: 'UTC', value: 0 };
  // oneHour = 3600000; // 1час === 3600000

  // convert Date (например => UTC to User timezone)
  // если TTypeTimezone === "Game" передаем, то обязательно надо передать timezone?: ITimezone
  // date === '2022-05-17T04:48:07.062344'
  convertDate(dateOld: string, timezoneFrom: TTypeTimezone, timezoneTo: TTypeTimezone, timezone: ITimezone | 'UTC' = 'UTC'): Date {
    if (!timezoneFrom || !timezoneTo) return new Date(dateOld);
    const date = typeof dateOld == 'object' ? dateOld : this.convertDateForSafari(dateOld);

    const timezone111 = timezone === 'UTC' ? this.utcTimezone : timezone;
    const type = timezoneFrom + 'To' + timezoneTo;
    if (type === 'UtcToUser') return this.convertDateUtcToUser(date);
    if (type === 'UserToUtc') return this.convertDateUserToUtc(date);
    if (type === 'UtcToGame') return this.convertDateUtcToGame(date, timezone111!);
    if (type === 'GameToUtc') return this.convertDateGameToUtc(date, timezone111!);
    else return new Date(date); // если timezoneFrom === timezoneTo, то не надо конвертировать дату
  }

  // дата с сервера приходит так '2021-12-13 11:56:28.045569' || '2021-02-16T17:00'
  // safari выдает ошибку new Date('2021-12-13 11:56:28.045569') без буквы 'T' и без 'Z'
  // буква 'Z' прибавляет зону пользователя (типа переводит из UTC в зону юзера)
  // это ломало всю логику. Поэтому теперь добавляю эти буквы и вычитаю зону пользователя
  convertDateForSafari(str: string): Date {
    let res: any;
    if (str.length < 11) { // '2022-09-01'
      res = this.convertDateUserToUtc(str + 'T00:00:00Z');
    } else if (str.length < 30 && str.length > 11) { // '2022-09-01 00:00:00.000000'.length == 27
      res = str.replace(' ', 'T'); // '2022-09-01T00:00:00.000000'
      if (!str.includes('Z')) res = res + 'Z';
      res = this.convertDateUserToUtc(res);
    } else { // 'Thu Sep 08 2022 18:34:06 GMT+0800 (Иркутск, стандартное время)'.length > 50
      res = str;
    }
    return new Date(Date.parse(res));
  }

  convertDateUtcToUser(date: Date): Date {
    return new Date(new Date(date).getTime() + (Number(this.userTimezoneValue) * this.oneHour));
  }

  convertDateUserToUtc(date: Date | string): Date {
    return new Date(new Date(date).getTime() - (Number(this.userTimezoneValue) * this.oneHour));
  }

  convertDateUtcToGame(date: Date | string, timezone: ITimezone): Date {
    return new Date(new Date(date).getTime() + (Number(this.getGameTimezoneValue(timezone)) * this.oneHour));
  }

  convertDateGameToUtc(date: Date | string, timezone: ITimezone): Date {
    return new Date(new Date(date).getTime() - (Number(this.getGameTimezoneValue(timezone)) * this.oneHour));
  }

  // return например '+8'
  get userTimezoneValue(): string {
    return (new Date().getTimezoneOffset() / -60).toString();
  }

  get idUserTimezone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  // return например '+8'
  getGameTimezoneValue(timezoneGame: ITimezone): string {
    return timezoneGame.value.toString();
  }

  // ===================================
  checkActualDate_byTodayUTC(type: 'isBefore' | 'isSame' | 'isAfter', date?: Date | string): boolean {
    let result = moment(date)[type](this.todayMidnightFormatDate_UTC, null); // можно поставить 'day' вместо null чтобы по времени не учитывать
    return result;
  }

  checkActualDate_byYesterdayUTC(type: 'isBefore' | 'isSame' | 'isAfter', date?: Date | string): boolean {
    let result = moment(date)[type](this.yesterdayMidnightFormatDate_UTC, null); // можно поставить 'day' вместо null чтобы по времени не учитывать
    return result;
  }

}
