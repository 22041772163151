<div class="add-new-payment-container">
  <h2>Add a New Payment Method</h2>

  <form id="payment-form" style="margin-top: 20px;">
    <!-- Stripe Payment Element -->
    <div id="payment-element">
      <!-- Elements will create form elements here -->
    </div>

  <div class="newpaymentButtons">
    <btn *ngIf="deviceS.isDesktop" text='Cancel' bcg='white' btnWidth='79' (emit)="onCancel()" style="margin-right: 12px;"></btn>
    <btn *ngIf="deviceS.isMobile" text='Cancel' bcg='white' (emit)="onCancel()" style="margin-bottom: 12px;"></btn>
    <button id="submit" class="o-btn-square-blueDark"  type="submit" (click)="submitPaymentDetails()">Submit</button>
  </div>
    <!-- Display error message -->
    <!-- <div id="error-message"> -->
      <!-- Errors will be displayed here -->
    <!-- </div> -->

  </form>
</div>
