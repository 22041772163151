export class EmbeddableTabbedList {
    embeddableId: string = "";
    tabName?: string;
}

export const embeddableTabList: Array<EmbeddableTabbedList> = [
    {
        tabName: "Games",
        embeddableId: "e234cafd-2965-4d0f-ae28-98348ae5205f"
    },
    {
        tabName: "Officials",
        embeddableId: "13b9a2d5-17fe-4de8-a4cc-b4f2ae24f646"
        // }, 
        // {
        //     tabName: "Financial",
        //     embeddableId: "53dfb2bc-e9a6-4a98-9049-11d1ba208d2c"
    }
]