<!-- убрал 21/01/2024 class wrapperDashboard. Если будут баги то вернуть -->
<div *ngIf="{ desktop: (deviceS.isDesktop$ | async), mobile: (deviceS.isMobile$ | async) } as obj" [formGroup]="form" class="o-wrapPage wrapperDashboard" wrapPage [widthPage]="1300">
  <!--  === FILTERS & TITLE ===========================-->
  <div class="o-header o-header--noHaveLinkPage">
    <linkPage-searchFilter [haveLinkPage]="false"></linkPage-searchFilter>
    <div class="o-header__content">
      <app-greet class="o-header__title" [forMobileFlexRow]="true"></app-greet>
      <div class="o-header__filters">
        <app-filters (emit)="emitSettingRequest($event)"></app-filters>
      </div>
      <app-filtersSelected></app-filtersSelected>
    </div>
  </div>

  <div class="o-content">
    <div class="overviewDashboardWrapper">
      <div class="overviewDashboard">
        <div *ngIf="obj.desktop" class="overviewDashboard__d3js comingSoon">
          <span>Available Payout</span>
          <div class="balance">{{ balance / 100 | numberFormat }}</div>
          <!--        <meSvg *ngIf='!commingSoon' svgName='test_d3js' matTooltip='Accepted Roles - 74%'></meSvg>-->
        </div>
        <div class="overviewDashboard__overview">
          <div class="overviewDashboard__overview__column">
            <div
              *ngFor="let item of arrForColumnComingSoon; let i = index"
              [hidden]="selectedTable !== item.id && obj.mobile"
              class="overviewDashboard__overview__column__item official"
              (click)="selectTable(item)"
              [ngClass]="{ isActive: selectedTable === item.id, 'swipe-left': animationState === 'swipe-left', 'swipe-right': animationState === 'swipe-right' }"
              (swipeleft)="onSwipe($event, i)"
              (swiperight)="onSwipe($event, i)"
            >
              {{ item.title }}
              <p class="o-text1">{{ item.value }}</p>
              <meSvg *ngIf="obj.desktop" matTooltip="{{ getTooltipText(item.id) }}" svgName="question_grey&16"></meSvg>
            </div>
          </div>
        </div>
      </div>

      <ul *ngIf="obj.mobile" class="pagination-dot">
        <li *ngFor="let item of arrForColumnComingSoon" (click)="selectTable(item)" [ngClass]="{ isActive: selectedTable === item.id }"></li>
      </ul>

      <div class="overviewDashboard hide-on-mobile">
        <div class="attentionWrapper">
          <div class="attentionWrapper-header">
            <div class="o-text4 coming-soon">Needs Attention! <span class="coming-soon__text">Coming Soon</span></div>
          </div>
          <div class="attentionWrapper-content-empty attentionWrapper-content-coming-soon">
            <div class="o-text1">Stay Tuned!</div>
            <div class="o-text6">Your 'Needs Attention' section is currently under development and will be coming soon. We appreciate your patience, and we look forward to sharing more with you shortly!</div>
            <img *ngIf="obj.desktop" src="assets/emptyAttentions.svg" alt="notch" />
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!(loading$ | async); else spinner">
      <ng-container [ngSwitch]="selectedTable">
        <div *ngSwitchCase="'accepted'" class="wrapperTotalGamesDashboard acceptedGames">
          <ng-container *ngIf="obj.desktop">
            <div class="wrapperTotalGamesDashboard__header">
              {{ getTitleById(selectedTable) }}
              <btn *ngIf="obj.desktop" [text]="viewAllText(acceptedGames?.length!)" (click)="routeToMyGames()" bcg="blueDark" btnHeight="36"></btn>
            </div>
            <div class="totalGames">
              <div class="totalGames__header">
                <div *ngFor="let itemHeader of dashboardS.arrHeaderTotalGames; let idx = index; let last = last" class="totalGames__header__item item_{{ idx }}">
                  {{ itemHeader.title }}
                </div>
              </div>
              <ng-container *ngIf="acceptedGames?.length; else nothingList">
                <div *ngFor="let gameItem of acceptedGames?.slice(0, 5); let idx = index" class="totalGames__itemTotalGame" [class.cancelled]="gameItem.gameStatus === 'CANCELLED'">
                  <div *ngIf="{ gameCANCELLED: gameItem.gameStatus == 'CANCELLED' } as objGameItem" class="totalGames__itemTotalGame__game item_0 game-number">
                    {{ gameItem.gameNumber }}
                  </div>
                  <div class="totalGames__itemTotalGame__date item_1">
                    <dateFormatted [date]="gameItem.date!" type="date" format="EE, MMM d, yyy"></dateFormatted>
                    <br />
                    <dateFormatted [date]="gameItem.date!" type="time" format="shortTime"></dateFormatted>
                  </div>
                  <div class="totalGames__itemTotalGame__ageLevel item_2">
                    <div>{{ gameItem | getAgeGenderLevel : 'ClassGame' }}</div>
                    <span>{{ gameItem.competition?.competitionName || 'TBD' }}</span>
                  </div>
                  <div class="totalGames__itemTotalGame__location item_3 =============acceptedGames">
                    <span>{{ gameItem | getLocNameCourtName }}</span>
                    <p [matTooltip]="gameItem | matTooltipForLocation">
                      {{ gameItem.location?.address | getLocationString : ['city', 'state'] }}
                    </p>
                  </div>
                  <div class="totalGames__itemTotalGame__teams item_4">
                    <span>{{ gameItem.homeTeam?.teamName || 'TBD' }} vs. </span>
                    <span>{{ gameItem.awayTeam?.teamName || 'TBD' }}</span>
                  </div>

                  <!-- gameDescription -->
                  <div stopPropagation class="totalGames__itemTotalGame__gameNotes">
                    <div [ngStyle]="{ visibility: gameItem.gameDescription && gameItem.gameDescription.length > 0 ? 'visible' : 'collapse' }" class="game-notes-button">
                      <meSvg [asButton22]="false" class="gameNotesSvg" svgName="message-text-square" (emit)="gameNotesPopupService.popupGameNotesForTableByGame(gameItem)" adminPermission></meSvg>
                    </div>
                  </div>

                  <div class="totalGames__itemTotalGame__officials">
                    <photos *ngIf="obj.desktop" type="game" [game]="gameItem" [showSmallCircle]="true"></photos>
                  </div>
                  <div class="totalGames__itemTotalGame__points item_6">
                    <meSvg svgName="circle_3points2&20" (click)="(null)"></meSvg>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>

          <!-- Mobile Version Start -->
          <ng-container *ngIf="obj.mobile">
            <app-games-table-mobile *ngIf="acceptedGames?.length; else nothingList" [data]="acceptedGames" [header]="getTitleById(selectedTable)"></app-games-table-mobile>
          </ng-container>
        </div>

        <div *ngSwitchCase="'unaccepted'" class="wrapperTotalGamesDashboard unacceptedGames">
          <app-unaccepted-games
            *ngIf="obj.desktop"
            (status)="setStatus($event)"
            [gameData]="unacceptedGames!"
            [headerData]="dashboardS.arrHeaderUnacceptedGames"
            [formData]="formValueAsDashboard"
            [selectedTable]="selectedTable"
          ></app-unaccepted-games>

          <!-- Mobile Version Start -->
          <ng-container *ngIf="obj.mobile">
            <app-games-table-mobile
              *ngIf="unacceptedGames?.length; else nothingList"
              [data]="unacceptedGames"
              [type]="'unaccepted'"
              [header]="getTitleById(selectedTable)"
              (status)="setStatus($event)"
            ></app-games-table-mobile>
          </ng-container>
        </div>

        <div *ngSwitchCase="'unacceptedselfassigned'" class="wrapperTotalGamesDashboard unacceptedselAssignedGames">
          <ng-container *ngIf="obj.desktop">
            <app-unaccepted-games
              (status)="setStatus($event)"
              [unAcceptedSelfAssign]="true"
              [gameData]="unacceptedselAssignedGames!"
              [headerData]="dashboardS.arrHeaderUnacceptedGames"
              [formData]="formValueAsDashboard"
              [selectedTable]="selectedTable"
            ></app-unaccepted-games>
          </ng-container>

          <!-- Mobile Version Start -->
          <ng-container *ngIf="obj.mobile">
            <app-games-table-mobile
              *ngIf="unacceptedselAssignedGames?.length; else nothingList"
              (status)="setStatus($event)"
              [data]="unacceptedselAssignedGames"
              [type]="'unacceptedselfassigned'"
              [header]="getTitleById(selectedTable)"
            ></app-games-table-mobile>
          </ng-container>
        </div>

        <div *ngSwitchCase="'upcomingGames'" class="wrapperTotalGamesDashboard =============upcomingGames">
          <div class="wrapperTotalGamesDashboard__header">
            Upcoming Games
            <btn text="View All" bcg="blueDark" btnHeight="36"></btn>
          </div>
          <div class="totalGames">
            <div class="totalGames__header">
              <div *ngFor="let itemHeader of dashboardS.arrHeaderTotalGames; let idx = index; let last = last" class="totalGames__header__item item_{{ idx }}">
                {{ itemHeader.title }}
              </div>
            </div>
            <ng-container *ngIf="dashboardS.arrUpcomingGames.length; else nothingList">
              <div *ngFor="let gameItem of dashboardS.arrTotalGames; let idx = index" class="totalGames__itemTotalGame">
                <div class="totalGames__itemTotalGame__game item_0">
                  <meSvg [svgName]="gameItem.game.isSelect | getChxSvgName" (click)="gameItem.game.isSelect = !gameItem.game.isSelect"></meSvg>
                  {{ gameItem.game.title }}
                </div>
                <div class="totalGames__itemTotalGame__date item_1">
                  {{ gameItem.date | date : 'fullDate' }}
                  <br />
                  {{ gameItem.date | date : 'shortTime' }}
                </div>
                <div class="totalGames__itemTotalGame__ageLevel item_2">
                  {{ gameItem.age }} <br />
                  {{ gameItem.level }}
                </div>
                <div class="totalGames__itemTotalGame__location item_3 =========upcomingGames">
                  {{ gameItem.location }}
                  <br />
                  {{ gameItem.teams }}
                </div>
                <div class="totalGames__itemTotalGame__teams item_4">
                  {{ gameItem.team1 }} vs.<br />
                  {{ gameItem.team2 }}
                </div>
                <div class="totalGames__itemTotalGame__officials item_5"><img src="assets/test/test_officials.svg" alt="" /></div>
                <div class="totalGames__itemTotalGame__points item_6">
                  <meSvg svgName="circle_3points2&20" (click)="(null)"></meSvg>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div *ngSwitchCase="'pendingAssignments'" class="wrapperTotalGamesDashboard ==============pendingAssignments">
          <div class="wrapperTotalGamesDashboard__header">
            New Assignments
            <btn text="View All" bcg="blueDark" btnHeight="36"></btn>
          </div>
          <div class="totalGames">
            <div class="totalGames__header">
              <div *ngFor="let itemHeader of dashboardS.arrHeaderTotalGames; let idx = index; let last = last" class="totalGames__header__item item_{{ idx }}">
                <meSvg *ngIf="idx == 0" [svgName]="itemHeader.isSelect | getChxSvgName" (click)="itemHeader.isSelect = !itemHeader.isSelect"></meSvg>
                {{ itemHeader.title }}
                <meSvg *ngIf="!last" [svgName]="itemHeader.sort | getSvgNameForSort"></meSvg>
              </div>
            </div>

            <div *ngFor="let gameItem of dashboardS.arrTotalGames; let idx = index" class="totalGames__itemTotalGame">
              <div class="totalGames__itemTotalGame__game item_0">
                <meSvg [svgName]="gameItem.game.isSelect | getChxSvgName" (click)="gameItem.game.isSelect = !gameItem.game.isSelect"></meSvg>
                {{ gameItem.game.title }}
              </div>
              <div class="totalGames__itemTotalGame__date item_1">
                {{ gameItem.date | date : 'fullDate' }} <br />
                {{ gameItem.date | date : 'shortTime' }}
              </div>
              <div class="totalGames__itemTotalGame__ageLevel item_2">
                {{ gameItem.age }} <br />
                {{ gameItem.level }}
              </div>
              <div class="totalGames__itemTotalGame__location item_3 =============pendingAssignments">
                {{ gameItem.location }}
                <br />
                {{ gameItem.teams }}
              </div>
              <div class="totalGames__itemTotalGame__teams item_4">
                {{ gameItem.team1 }} vs.<br />
                {{ gameItem.team2 }}
              </div>
              <div class="totalGames__itemTotalGame__officials item_5"><img src="assets/test/test_officials.svg" alt="" /></div>
              <div class="totalGames__itemTotalGame__points item_6">
                <meSvg svgName="circle_3points2&20" (click)="(null)"></meSvg>
              </div>
            </div>
          </div>
        </div>

        <div *ngSwitchCase="'pendingResponse'" class="wrapperTotalGamesDashboard ========pendingResponse">
          <div class="wrapperTotalGamesDashboard__header">
            Awaiting Response
            <btn text="View All" bcg="blueDark" btnHeight="36"></btn>
          </div>
          <div class="totalGames">
            <div class="totalGames__header">
              <div *ngFor="let itemHeader of dashboardS.arrHeaderTotalGames; let idx = index; let last = last" class="totalGames__header__item item_{{ idx }}">
                <meSvg *ngIf="idx == 0" [svgName]="itemHeader.isSelect | getChxSvgName" (click)="itemHeader.isSelect = !itemHeader.isSelect"></meSvg>
                {{ itemHeader.title }}
                <meSvg *ngIf="!last" [svgName]="itemHeader.sort | getSvgNameForSort"></meSvg>
              </div>
            </div>

            <div *ngFor="let gameItem of dashboardS.arrTotalGames; let idx = index" class="totalGames__itemTotalGame">
              <div class="totalGames__itemTotalGame__game item_0">
                <meSvg [svgName]="gameItem.game.isSelect | getChxSvgName" (click)="gameItem.game.isSelect = !gameItem.game.isSelect"></meSvg>
                {{ gameItem.game.title }}
              </div>
              <div class="totalGames__itemTotalGame__date item_1">
                {{ gameItem.date | date : 'fullDate' }} <br />
                {{ gameItem.date | date : 'shortTime' }}
              </div>
              <div class="totalGames__itemTotalGame__ageLevel item_2">
                {{ gameItem.age }} <br />
                {{ gameItem.level }}
              </div>
              <div class="totalGames__itemTotalGame__location item_3 =========pendingResponse">
                {{ gameItem.location }}
                <br />
                {{ gameItem.teams }}
              </div>
              <div class="totalGames__itemTotalGame__teams item_4">
                {{ gameItem.team1 }} vs.<br />
                {{ gameItem.team2 }}
              </div>
              <div class="totalGames__itemTotalGame__officials item_5"><img src="assets/test/test_officials.svg" alt="" /></div>
              <div class="totalGames__itemTotalGame__points item_6">
                <meSvg svgName="circle_3points2&20" (click)="(null)"></meSvg>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #nothingList>
      <div *ngIf="deviceS.isMobile$ | async" class="o-text4" style="margin-bottom: 16px">{{ getTitleById(selectedTable) }}</div>
      <nothingList [tableEmpty]="filtersS.searchValue | getTableEmptyForDashboard : selectedTable">
        <div class="buttons" class="class_ngContent_btn_for_nothingList">
          <btn
            text="Manage Availability"
            [routerLink]="['/planner']"
            [queryParams]="{ type: 'Availability' }"
            bcg="newGreen"
            (emit)="(null)"
            [btnWidth]="obj.desktop ? 188 : '100%'"
            [btnHeight]="obj.desktop ? 48 : 36"
          ></btn>
          <btn text="View Games" routerLink="/openGames" bcg="white" (emit)="(null)" [btnWidth]="obj.desktop ? 132 : '100%'" [btnHeight]="obj.desktop ? 48 : 36"></btn>
        </div>
      </nothingList>
    </ng-template>
  </div>
</div>

<!--=== SPINNER =====================================-->
<ng-template #spinner>
  <spinner></spinner>
</ng-template>
