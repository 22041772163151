import { CustomDatesService } from '@classes/CustomDates';
import { OtherService } from '@services/other.service';
import { IDatePeriod, objAmountDaysForChoosePeriod } from '@components/__drop_inputs_matSelect/date-range/dateRange';
import { ClassFilterDateRange } from '@components/filters/filters';
import { Injectable } from '@angular/core';

@Injectable()
export class DateRangeService {
  format: 'YYYY-MM-DDTHH:mm:ss' | 'YYYY-MM-DD' = 'YYYY-MM-DDTHH:mm:ss';

  constructor(
    private datesS: CustomDatesService,
    private otherS: OtherService,
  ) {
  }

  getFormatDate(date: string, type: 'from' | 'to'): string {
    let result: string | Date = '';
    const arr = date?.split('-'); // ['2023', '12', '01']
    const year = arr[0];
    const month = +arr[1] - 1;
    const day = arr[2];
    const time = type == 'from' ? 'T00:00:00' : 'T23:59:59';
    result = this.datesS.formatDate('YYYY-MM-DD', new Date(+year, +month, +day)) + time;
    result = this.otherS.convertDate(result as string, 'User', 'Utc');
    result = this.datesS.formatDate(this.format, result);
    return result;
  }

  checkDatePeriod(filterDateRange: ClassFilterDateRange, forTest: string): ClassFilterDateRange {
    // console.log('checkDatePeriod :', forTest, filterDateRange.period , filterDateRange.datePeriod, filterDateRange);
    const result: ClassFilterDateRange = new ClassFilterDateRange({
      ...filterDateRange, fromTo_formatted: filterDateRange.fromTo_formatted || '',
    });
    if (!result.datePeriod) result.datePeriod = {};
    const todayFormatDate = this.datesS.todayMidnightFormatDate;

    // === FOR arrPeriods =============================================
    if (filterDateRange.arrPeriods?.length) {
      if (filterDateRange.period === 'Today') result.datePeriod.from = this.datesS.tomorrowMidnightFormatDate;
      // console.log('checkDatePeriod 111 :', result.datePeriod, result);
    }

    // === FOR CURRENT =============================================
    if (filterDateRange.typePeriod == 'current') {
      result.datePeriod.from = todayFormatDate;
      if (!filterDateRange.period) result.datePeriod.to = ''; // !!! для пустого значения тоже при перключении вкладок и при загрузке страницы
      if (filterDateRange.period === 'Today') result.datePeriod.to = this.datesS.tomorrowMidnightFormatDate;
      // console.log('checkDatePeriod 222 :', result.datePeriod, result);
    }

    // === FOR PAST =============================================
    if (filterDateRange.typePeriod == 'past') {
      result.datePeriod.to = todayFormatDate;
      if (!filterDateRange.period) result.datePeriod.from = ''; // !!! для пустого значения тоже при перключении вкладок и при загрузке страницы
      // console.log('checkDatePeriod 333 :', result.datePeriod, result);
    }
    result.datePeriod = this.getFormattedDate({ ...filterDateRange, datePeriod: result.datePeriod });
    // console.log('checkDatePeriod 444 :', result.datePeriod, result);

    // === перевод в UTC ========================
    if (result.datePeriod?.from) {
      // result.datePeriod.from = this.datesS.todayMidnightFormatDate_UTC;
      result.datePeriod.from = this.otherS.convertDate(result.datePeriod?.from as string, 'User', 'Utc');
      result.datePeriod.from = this.datesS.formatDate(this.format, result.datePeriod?.from);
      // console.log('checkDatePeriod 555 :', result.datePeriod, result);
    }
    if (result.datePeriod?.to) {
      // result.datePeriod.to = this.datesS.yesterdayMidnightFormatDate_UTC;
      result.datePeriod.to = this.otherS.convertDate(result.datePeriod?.to as string, 'User', 'Utc');
      result.datePeriod.to = this.datesS.formatDate(this.format, result.datePeriod?.to);
      // console.log('checkDatePeriod 666 :', result.datePeriod, result);
    }

    // console.log('checkDatePeriod RESULT :', result.datePeriod, result);
    return result;
  }

  private getFormattedDate(filterDateRange: ClassFilterDateRange): IDatePeriod {
    const { period } = filterDateRange;
    const result: IDatePeriod = { ...filterDateRange.datePeriod };
    const todayFormatDate = this.datesS.todayMidnightFormatDate;
    if (typeof objAmountDaysForChoosePeriod[period!] == 'number') {
      const amountDays = objAmountDaysForChoosePeriod[period!] as number;
      // console.log('111111111111111111111 amountDays:', amountDays)
      if (amountDays > 0) {
        result.to = this.datesS.getFormatDatePlusFewDays(this.format, amountDays);
        result.from = todayFormatDate;
        // console.log(' 111111 from :', result.from, '  to :', result.to)
      } else {
        result.from = this.datesS.getFormatDatePlusFewDays(this.format, amountDays);
        result.to = todayFormatDate;
        // console.log(' 22222 from :', result.from, '  to :', result.to)
      }
    } else {
      // console.log('2222222222222222222222 :', )
      if (period === 'Yesterday') {
        result.from = this.datesS.formatDate(this.format, this.datesS.yesterdayMidnightFormatDate);
        result.to = this.datesS.formatDate(this.format, this.datesS.todayMidnightFormatDate);
      }
      if (period === 'Month to Date') {
        result.from = this.datesS.formatDate(this.format, this.datesS.firstDayMonth);
        result.to = todayFormatDate;
      }
      if (period === 'Quarter to Date') {
        result.from = this.datesS.formatDate(this.format, this.datesS.firstDayQuarterMonth);
        result.to = todayFormatDate;
      }
      if (period === 'Year to Date') {
        result.from = this.datesS.formatDate(this.format, this.datesS.dayYearAgo);
        result.to = todayFormatDate;
      }
      if (period === 'Tomorrow') {
        result.from = this.datesS.formatDate(this.format, this.datesS.tomorrowDate);
        result.to = this.datesS.formatDate(this.format, this.datesS.afterTomorrowMidnightFormatDate);
      }
      if (period === 'Today' &&  window.location.href.includes('/payment/')) {
        result.from = this.datesS.formatDate(this.format, this.datesS.todayMidnightFormatDate);
        result.to = this.datesS.formatDate(this.format, this.datesS.tomorrowMidnightFormatDate);
      }
    }
    return result;
  }
}
