<div class="wrapperCompetitions wrapperCompetitionsPayScales">
  <competitionsNavigation page="payScales"></competitionsNavigation>
  <form [formGroup]="form">
    <div class="headerSetupCompetition">
      <svgAndText text="Pay Scales" leftSvgName="arrow_squareLeft&24" typeText="text1-blueDark" (leftSvgEmit)="competitionS.goToBack()"></svgAndText>
      <div class="headerSetupCompetition__btns">
        <btn text="Save & Close" bcg="white" btnHeight="36" (click)="competitionsBtnsEmit(false)" [disabled]="!form.valid || reqPending"></btn>
        <btn text="Save & Proceed to Next Step" bcg="blueDark" btnHeight="36" (click)="competitionsBtnsEmit(true)" [disabled]="!form.valid || reqPending"></btn>
      </div>
    </div>

    <div class="tableSetupCompetition contentSetupCompetition">
      <div class="tableSetupCompetition__header">
        <div *ngFor="let headerItem of form.controls?.arrTableHeader?.value; let i = index" class="tableSetupCompetition__header__item tableItem tableItem--{{ headerItem | strCase : 'camel' }}">
          {{ headerItem }}
          <meSvg *ngIf="headerItem == 'Other Rate'" svgName="question_grey&16" textMatTooltip="Optional field to add all other rates to be billed. For example, software fees."></meSvg>
        </div>
      </div>

      <div formArrayName="arrControls" class="tableSetupCompetition__content">
        <div *ngFor="let payScale of arrControls?.controls; let firstPayScale = first; let idxPayScale = index; let lastPayScale = last" [formGroupName]="idxPayScale" class="tableSetupCompetition__content__item">
          <div class="tableSetupCompetition__content__item__goToTop">
            <app-dropdown formControlName="gameTypeDrop" [array]="competitionS.arrGameTypeDrop" placeholder="Game Type" class="tableItem--gameType"></app-dropdown>
            <app-dropdown formControlName="age" [array]="competitionS.ageGroups" class="tableItem--age" placeholder="Age"></app-dropdown>
            <app-dropdown formControlName="level" [array]="competitionS.levels" class="tableItem--level" placeholder="Level"></app-dropdown>
          </div>

          <div formArrayName="crew" class="tableSetupCompetition__content__item__crewRates">
            <div
              *ngFor="let crew of payScale | getCrewArrayForPayScales; let idxCrew = index; let firstCrew = first; let lastCrew = last"
              [formGroupName]="idxCrew"
              class="tableSetupCompetition__content__item__crewRates__itemCrew"
            >
              <div #elRef class="tableItem--crewSize">
                {{ idxCrew + 1 }}
                <meSvg svgName="user_1&16"></meSvg>
                <meSvg svgName="arrow_chevronBottom&20" [matMenuTriggerFor]="menuCrewSize" class="{{ !lastCrew ? 'o-hidden' : '' }}"></meSvg>
                <mat-menu #menuCrewSize="matMenu">
                  <button *ngFor="let valueCrewSize of competitionS.crewSize" (click)="changeCrewSize(idxPayScale, valueCrewSize)">
                    {{ valueCrewSize }}
                  </button>
                  <button [ngStyle]="elRef | getWidthMatMenu" class="hiddenMatOption"></button>
                </mat-menu>
              </div>

              <ng-container *ngFor="let columnNumber of competitionS.competition?.maxCrewSize | arrayFromNumber">
                <inputCtrl
                  *ngIf="columnNumber | isShowCrewOfficial : 'HTML'"
                  formControlName="official{{ columnNumber }}"
                  class="tableItem--official"
                  [isTextCenter]="true"
                  [showDollar]="true"
                  placeholder="--"
                  [isNumber]="true"
                  (focusOutEmit)="changeValueCrewItem(payScale, crew, columnNumber, $event)"
                  [addDollarAndZero_in_methodWriteValue]="true"
                ></inputCtrl>
              </ng-container>

              <inputCtrl
                [formControlName]="'groupAssignorRate'"
                class="tableItem--groupAssignorRate"
                [isTextCenter]="true"
                [showDollar]="true"
                [svgSuffix]="crew.controls.groupAssignorRateVisible?.value ? 'eye_true&20' : 'eye_false&20'"
                (svgRightEmit)="crew.controls.groupAssignorRateVisible.patchValue(!crew.controls.groupAssignorRateVisible?.value)"
                placeholder="--"
                [isNumber]="true"
              ></inputCtrl>
              <inputCtrl formControlName="subAssignorRate" class="tableItem--subAssignorRate" [isTextCenter]="true" [showDollar]="true" placeholder="--" [isNumber]="true"></inputCtrl>
              <inputCtrl formControlName="otherRate" class="tableItem--otherRate" [isTextCenter]="true" [showDollar]="true" placeholder="--" [isNumber]="true"></inputCtrl>

              <div class="tableItem--totalRate">
                <span>{{ crew?.controls?.totalRate?.value | currency }}</span>
              </div>
              <div class="tableItem--actions">
                <meSvg *ngIf="firstCrew" svgName="squareTwo&44" (click)="isCloneEnable(payScale) && copyItem(payScale)" [class.disabled]="!isCloneEnable(payScale)" [attr.disabled]="!isCloneEnable(payScale)"></meSvg>
                <deleteItemForCompetitions
                  *ngIf="firstCrew"
                  page="payScales"
                  [id]="payScale?.value?.id!"
                  [disabled]="arrControls?.controls?.length == 1"
                  (responseEmit)="form.controls.arrControls?.removeAt(idxPayScale)"
                ></deleteItemForCompetitions>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <btnAndLine text="Add New +" [bcg]="competitionS.bcg" class="o-mg-t-32" (emit)="addNew()"></btnAndLine>
  </form>
</div>
