import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'skeleton',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonComponent {
  // Number of skeleton rows
  @Input() skeletonCount: number = 5;

  // Generate an array for ngFor based on skeletonCount
  get skeletonCountArray(): number[] {
    return Array(this.skeletonCount).fill(0);
  }

  @HostBinding('class.no-margin')
  @Input() isNoMargin = false;

  @Input() positionAbsolute: boolean = false;
}

