import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ClassDrop } from "@components/__drop_inputs_matSelect/dropdown/dropdown";

@Injectable({
    providedIn: 'root',
})
export class EnableSelfRequestService {
    #enableSelfRequestSubject$ = new BehaviorSubject<boolean>(false);
    #selfRequestLimit$ = new BehaviorSubject<ClassDrop>({});

    get enableSelfRequestSubject(){
        return this.#enableSelfRequestSubject$.getValue();
    }

    set enableSelfRequestSubject(value: boolean){
        this.#enableSelfRequestSubject$.next(value);
    }

    get selfRequestLimitSubject(){
        return this.#selfRequestLimit$.getValue();
    }

    set selfRequestLimitSubject(value: ClassDrop){
        this.#selfRequestLimit$.next(value);
    }
}
