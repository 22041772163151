import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class GlobasConst {
    public get dateTimeFormat(): string {
        return 'MMM d, y, h:mm a';
    }
    public get dateFormat(): string {
        return 'MMM d, y';
    }

    public formatDateFromServer(dateString: string | undefined): string {
        if (!dateString) return '';
    
        // Extract the date part from the input string
        const dateParts = dateString.split(' ');
        if (dateParts.length < 4) return dateString; 
    
        const day = dateParts[0]; // e.g., '3'
        const month = dateParts[1]; // e.g., 'Jan'
        const year = dateParts[2]; // e.g., '2025'
    
        // Reformat to 'Jan 3, 2025'
        return `${month} ${parseInt(day, 10)}, ${year}`;
    }
    
    public get timeFormat(): string {
        return 'h:mm a';
    }

    public get moneyFormat(): any {
        return {
            currencyFormat: 'USD',
            currencyDisplay: 'symbol',
            currencyPrecision: '1.2-2',
        }
    }
}