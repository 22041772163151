import { Pipe, PipeTransform } from '@angular/core';
import { ClassTransfer } from '@models/transfer.model';
import { ClassMyWalletItemTableForMobile } from '@app/dir_officials/page-my-wallet/my-wallet';

// !!! чтобы понять для чего этот пайп, нужно посмотреть дизайн в фигме для мобилы для MyWallet
@Pipe({ name: 'getArrayForMyWalletTableForMobile', standalone: true })
export class GetArrayForMyWalletTableForMobilePipe implements PipeTransform {

  transform(arrContent: Array<ClassTransfer> | null): Array<ClassMyWalletItemTableForMobile> {
    if (!arrContent?.length) return [];

    const mapResult: Record<string, ClassMyWalletItemTableForMobile> = {};

    arrContent?.forEach((transfer) => {
      if (mapResult[transfer.createdAt_M_d_yyyy!]) {
        mapResult[transfer.createdAt_M_d_yyyy!].arrayTransfer?.push(transfer);
      } else {
        mapResult[transfer.createdAt_M_d_yyyy!] = new ClassMyWalletItemTableForMobile(transfer);
      }
    });

    const tempArr = Object.entries(mapResult).sort(this.sortByDate);
    const arrResult: Array<ClassMyWalletItemTableForMobile> = tempArr.map(el => el[1]);

    arrResult?.forEach(el => {
      el.allTotal = el.arrayTransfer?.reduce((sum, transfer) => sum + (transfer.transferTotalInCents || 0), 0);
    });
    return arrResult;
  }

  private sortByDate(a: [string, ClassMyWalletItemTableForMobile], b: [string, ClassMyWalletItemTableForMobile]) {
    const dateA = new Date(a[0]);
    const dateB = new Date(b[0]);
    return dateB.valueOf() - dateA.valueOf();
  }

}
