import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelperClass } from '@classes/Helper-Classes';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { CompetitionService } from '@app/dir_group_assignor/competitions/competition.service';
import { SvgAndTextComponent } from '@components/__svg_img/svg-and-text/svg-and-text.component';
import { BtnComponent } from '@components/btn/btn.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { BtnAndLineComponent } from '@components/btn-and-line/btn-and-line.component';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { ChxComponent } from '@components/chx/chx.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClassCompetition, ClassCompetitionAgeGroup, TGamePeriodDrop } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { MainService } from '@services/main.service';
import {
  CompetitionsNavigationComponent,
} from '@app/dir_group_assignor/competitions/helperComponentsCompetitions/competitions-navigation/competitions-navigation.component';
import { ActivatedRoute } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { THttpMethod } from '@services/base-api';
import { OtherService } from '@services/other.service';
import {
  DeleteItemForCompetitionsComponent,
} from '@app/dir_group_assignor/competitions/helperComponentsCompetitions/delete-item-for-competitions/delete-item-for-competitions.component';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { CheckActiveService } from '@app/dir_group_assignor/competitions/services/checkActiveService';
import { EnableSelfRequestService } from '@app/dir_group_assignor/competitions/services/self-request-state.service';
import { MeService } from '@services/me.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { of, switchMap } from 'rxjs';
import { ApiCompetitionService } from '@app/dir_group_assignor/competitions/api-competition.service';

interface IFormCompetitionsGroups {
  arrTableHeader?: FormControl<Array<string>>;
  arrControls?: FormArray<FormGroup<IFormItemCompetitionsGroups>>; // arrControls?: FormArray<FormGroup<IFormItemCompetitionsOfficialLabels>>;
}

interface IFormItemCompetitionsGroups {
  id?: FormControl<string>;
  competitionId?: FormControl<string>;
  gameAgeDescription?: FormControl<string>;
  gamePeriodDrop?: FormControl<TGamePeriodDrop>;
  periodLength?: FormControl<string>;
  totalDurations?: FormControl<string>; // (Mins)
  officialSelfAssign?: FormControl<boolean>;
  officialSelfRequest?: FormControl<boolean>;
  selfApplyLimit?: FormControl<string>;
}

@UntilDestroy()
@Component({
  selector: 'competitionsGroups',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SvgAndTextComponent, BtnComponent, InputCtrlComponent, BtnAndLineComponent, FormsModule, DropFormCtrlComponent, ChxComponent, SvgComponent, MatTooltipModule, CompetitionsNavigationComponent, MatProgressSpinnerModule, BtnWrapComponent, DeleteItemForCompetitionsComponent, DropdownComponent],
  templateUrl: './competitions-groups.component.html',
  styleUrls: ['./competitions-groups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompetitionsGroupsComponent extends HelperClass implements OnInit {
  form!: FormGroup<IFormCompetitionsGroups>;

  public enableSelfRequestService = inject(EnableSelfRequestService);
  isEnableSelfRequest: boolean = true; // (Artur comment) как будет закончен бэк убрать

  constructor(
    private formBuilder: UntypedFormBuilder,
    public competitionS: CompetitionService,
    private mainS: MainService,
    private apiCompetitionS: ApiCompetitionService,
    private meS: MeService,
    public route: ActivatedRoute,
    public otherS: OtherService,
    public cd: ChangeDetectorRef,
    private readonly checkActiveService: CheckActiveService,
  ) {
    super(cd);
    // this.enableSelfRequestService.enableSelfRequestSubject = this.isEnableSelfRequest;
    // this.route.params.pipe(untilDestroyed(this)).subscribe((params) => this.competitionS.checkParams(params));
    this.createForm();
  }

  ngOnInit() {
    this.subscribeToCompetition();
  }

  subscribeToCompetition(): void {
    this.competitionS.competition$.pipe(
      switchMap((res) => {
        return (res?.id) ? this.apiCompetitionS.getSelfLimited(res.id, true) : of(null);
      }),
      untilDestroyed(this),
    ).subscribe((res) => {
      if (!res) return;
      this.enableSelfRequestService.enableSelfRequestSubject = Number(res?.selfApplyLimit) > 0 || !!res?.selfApplyLimit || this.enableSelfRequestService.enableSelfRequestSubject;
      this.enableSelfRequestService.selfRequestLimitSubject = { titleCase: `${res?.selfApplyLimit}`, upperCase: `${res?.selfApplyLimit}` };
    });
  }

  // === FORM ==============
  createForm(): void {
    this.form = this.formBuilder.group({
      arrTableHeader: new FormControl([]) as FormControl<Array<string>>,
      arrControls: this.formBuilder.array([]),
    });
    this.form.controls.arrTableHeader?.setValue(this.competitionS.arrTableHeaderAgeGroups);
    this.competitionS.ageGroups?.forEach((el) => {
      this.arrControls.push(this.formBuilder.group({
        ...el,
        competitionId: this.competitionS.competition?.id,
      }));
    });

    // !!! from setForm()
    this.competitionS.ageGroups?.forEach((el, idxArrControls) => {
      this.arrControls.clear();
      this.competitionS.competition?.ageGroups?.forEach((item) => {
        this.addNew(item);
      });
    });
    setTimeout(() => this.cd.detectChanges());
  }

  get arrControls(): FormArray<FormGroup<IFormItemCompetitionsGroups>> {
    return this.form.controls?.arrControls!;
  }

  addNew(item?: ClassCompetitionAgeGroup): void {
    const newFormGroup: FormGroup<IFormItemCompetitionsGroups> = this.formBuilder.group({
      id: [item?.id || ''],
      competitionId: [item?.competitionId || ''],
      gameAgeDescription: [item?.gameAgeDescription || '', Validators.required],
      gamePeriodDrop: [item?.gamePeriodDrop || '', Validators.required],
      periodLength: [item?.periodLength || '', Validators.required],
      totalDurations: [item?.totalDurations || '', Validators.required],
      officialSelfRequest: [item?.officialSelfRequest],
      selfApplyLimit: [item?.selfApplyLimit],
      officialSelfAssign: [!!item?.officialSelfAssign],
    });
    this.arrControls.push(newFormGroup);
  }

  copyItem(item: FormGroup<IFormItemCompetitionsGroups>): void {
    const copyItem: FormGroup<IFormItemCompetitionsGroups> = this.otherS.deepClone(item);
    copyItem.patchValue({ ...item.value, id: '' });
    (this.form?.controls.arrControls as FormArray).push(copyItem);
    this.cd.detectChanges();
  }

  // === competitions Btns Emit =========================
  async competitionsBtnsEmit(isNext: boolean): Promise<void> {
    const competitionId = this.competitionS?.competition?.id;
    const isActiveModal = await this.checkActiveService.checkActive(competitionId, true);
    if (isActiveModal) {
      if (!competitionId) this.methodCompetitionAgeGroups(isNext, 'post'); // create
      if (competitionId) this.methodCompetitionAgeGroups(isNext, 'put'); // update
    }

  }

  methodCompetitionAgeGroups(isNext = false, httpMethod: THttpMethod): void { // createCompetitionAgeGroups || updateCompetitionAgeGroups
    if (this.startRequest()) return;
    const competitionId = this.competitionS.competition.id!;
    const sendObj = {
      id: competitionId,
      ageGroups: this.form?.getRawValue().arrControls as Array<ClassCompetitionAgeGroup>
    };
    this.apiCompetitionS.updateSelfLimit(this.enableSelfRequestService.selfRequestLimitSubject?.titleCase, competitionId,
      this.meS.meId, this.meS.meRole).pipe(untilDestroyed(this)).subscribe(res => {
      this.competitionS.addCompetitionIdBeforeSendToServer(sendObj.ageGroups);
      // this.apiCompetitionS.methodCompetitionAgeGroups(sendObj, competitionId, httpMethod).toPromise()
      this.apiCompetitionS.methodCompetitionAgeGroups(sendObj, httpMethod).toPromise()
        .then((res?: Pick<ClassCompetition, 'ageGroups'>) => {
          if (!res?.ageGroups) return;
          this.competitionS.competition.ageGroups = res.ageGroups;
          isNext ? this.competitionS.nextStep('ageGroups') : this.competitionS.goToDashboard();
        })
        .catch((err: any) => {
        })
        .finally(() => this.endRequest());
    });
  }
}

