<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
  mobile: (deviceS.isMobile$|async),
} as obj' class='wrapperMeProfileAccount profilecert'>

  <div style="display: flex; flex-direction: column;">
    <headTitle *ngIf="obj.desktop" text='Certifications' svgLeft='arrow_squareLeft&24'
               (svgLeftEmit)='otherS.locationBack()' typeText='text1-blueDark'></headTitle>
    <div class="title__mobile" *ngIf="obj.mobile">
      <meSvg svgName='arrow_squareLeft&24' (click)="otherS.locationBack()" style="margin-right: 10px;"></meSvg>
      Certifications
    </div>
    <span class="contentCertifications__subheader">If you believe something is missing or is listed incorrectly, advise the individual you are searching for to contact U.S. Soccer.</span>
  </div>

  <titleAndNavigationForProfile *ngIf='obj.mobile' [typePageProfile]='currentProfile!' [idUser]='profileUserS.id'></titleAndNavigationForProfile>

  <spinner *ngIf="loading"></spinner>
  <div *ngIf="!loading" class='wrapperMeProfileAccount__content'>
    <div class='wrapperMeProfileAccount__block'>
      <ng-container *ngIf="organizationUserData && !isError404; else nodata">
        <div class='contentCertifications' [ngClass]="{'border-red': userLicenses && userLicenses.length === 0}">
          <div class='contentCertifications__details'>
            <div class="contentCertifications__details__main">
              <div class="contentCertifications__ussf">
                <img #imageBcgRef src='assets/certificate.svg' alt='' style="margin-right: 10px;">
                USSF ID: {{ organizationUserData?.organizationUserId }}
              </div>
              <div class="delete-icon-container">
                <img src='assets/Icon_trash.svg' (click)='deleteCertificate()' style="height: 24px; width: 24px; cursor: pointer;" />
              </div>
            </div>
            <!-- <img src='assets/Icon_trash.svg' (click)='deleteCertificate()' style="height: 24px; width: 24px; cursor: pointer;" /> -->
            <div *ngIf="userLicenses && userLicenses.length === 0">
              <span class='o-colorRed'>USSF ID was validated with a missing referee category.</span>
            </div>
            <div class="contentCertifications__subdetails" style="margin-top: 15px;">
              <div>Official Grade: <span class="contentCertifications__ussf__bold">{{ getIssueName() }}</span></div>
              <div>Issue Date: <span class="contentCertifications__ussf__bold">{{ getIssueDate() }}</span></div>
              <div>Valid Through: <span class="contentCertifications__ussf__bold">{{ getExpiryDate() }}</span></div>
              <div>Referee Experience: <span class="contentCertifications__ussf__bold">{{ organizationUserData?.refereeExperience }} years</span></div>
            </div>
          </div>
          <!-- <div class="toggle-button" (click)="toggleViewMore()" *ngIf="userLicenses && userLicenses.length > 0">
    {{ showUserLicenses ? 'View Less' : 'View More' }}
    </div> -->

          <!-- <div class="toggle-button" *ngIf="userLicenses_taggle?.length > 0">
      <span class="view-toggle" (click)="toggleViewMore()">
          {{ showUserLicenses ? 'View Less' : 'View More' }}
      </span>
    </div> -->
          <div class="toggle-button">
            <div class="status-container">
              <div *ngIf="certified" class="eligible-text">
                <span>Eligible</span>
              </div>
              <div class="notEligible" *ngIf="!certified">
                Not Eligible
              </div>
            </div>
            <span *ngIf="userLicenses_taggle?.length > 0" class="view-toggle" (click)="toggleViewMore()">
              {{ showUserLicenses ? 'View Less' : 'View More' }}
            </span>
          </div>

        </div>
      </ng-container>
      <ng-container *ngIf="!isError404 && showUserLicenses">
        <div class="licence__viewmore">
          <div *ngFor="let license of userLicenses_taggle" class="license-box">
            <div class="license-details">
              <div class="license-details__title">{{ license.license.name }}</div>
              <div class="license-details__info">
                <div>Issue Date: <span class="license-details__info__value">{{ getFormattedDate(license.issueDate) }}</span></div>
                <div>Valid Through: <span class="license-details__info__value">{{ getFormattedDate(license.expiryDate) }}</span></div>
                <div>Issued By: <span class="license-details__info__value">{{ license.issuer }}</span></div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-template #nodata>
        <div class='contentCertifications__add'>
          <div class="contentCertifications__name">You do not have any active certifications</div>
          <btn *ngIf="obj.desktop" text='Add Certification +' (click)="openAddCertPopup()" bcg='newGreen' [btnWidth]='311' [btnHeight]='36'></btn>
          <btn *ngIf="obj.mobile" text='Add Certification +' (click)="openAddCertPopup()" bcg='newGreen' [btnHeight]='36'></btn>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="page-background" [ngClass]="{ 'popup-open': isAddCertPopupOpen }">
    <div *ngIf="isAddCertPopupOpen" class='defaultPopupWrapper popup-open'>
      <headTitle text='Add Certifications' svgRight='cross_grey&24' (svgRightEmit)='closeAddCertPopup()' typeText='text4-blueDark'></headTitle>
      <div *ngIf="obj.mobile" class="popup-header">
        <div class="popup-title">Add Certifications</div>
        <div class="close-icon" (click)="closeAddCertPopup()"><img src="assets/cross/cross_grey&24.svg" alt="Close"></div>
      </div>
      <field>
        <div class="label-container">Under which governing body did you obtain a certification?</div>
        <app-dropdown [(ngModel)]='certDropDown' [array]='arrCertDropDownDrop' placeholder='United States Soccer Federation(USSF)'></app-dropdown>
        <span class="dropdown-arrow">
          <i class="fa fa-angle-down"></i>
        </span>
      </field>

      <field>
        <div class="tooltip-container">
          <div class="label-container">
            Enter USSF ID
            <div class="tooltip-icon">
              <img src='assets/vector-phone.svg' style="height: 16px; width: 16px;" />
              <div class="hover-box">
                Search for USSF ID number <a href="https://learning.ussoccer.com/directory" target="_blank">here</a>
              </div>
            </div>
          </div>
          <inputCtrl [(ngModel)]="value" height='20' placeholder='Enter I.D. Registration' (input)="removeHyphens()" pattern="[0-9]{16}" maxlength="16"></inputCtrl>
          <div class="error-message" *ngIf="isInvalidUSSFID()">USSF ID must contain 16 digits</div>
        </div>
      </field>


      <div *ngIf="obj.desktop" class='defaultPopupWrapper__textBottom'></div>

      <btnWrap *ngIf='deviceS.isDesktop$ | async'>
        <btn text='Cancel' bcg='white' (emit)='closeAddCertPopup()'></btn>
        <btn text='Save' bcg='blueDark' [disabled]='isSaveDisabled()' (emit)='addCertificate()'></btn>
      </btnWrap>
      <btnWrap *ngIf='deviceS.isMobile$ | async'>
        <btn text='Save' bcg='blueDark' [disabled]='isSaveDisabled()' (emit)='addCertificate()'></btn>
        <btn text='Cancel' bcg='white' (emit)='closeAddCertPopup()'></btn>
      </btnWrap>
    </div>
  </div>

  <div class="page-background" [ngClass]="{ 'popup-open': isDeleteConfirmationOpen }">
    <div class='defaultPopupWrapper  defaultPopupWrapper__delete popup-open' *ngIf="isDeleteConfirmationOpen">
      <headTitle text='Are you sure you want to delete your Cert ID?' svgRight='cross_grey&24' (svgRightEmit)='closeDeleteConfirmation()' typeText='text4-blueDark'></headTitle>
      <div *ngIf="obj.mobile" class="popup-header">
        <div class="popup-title">Are you sure you want to delete your Cert ID?</div>
        <div class="close-icon" (click)="closeDeleteConfirmation()"><img src="assets/cross/cross_grey&24.svg" alt="Close"></div>
      </div>
      <div class='defaultPopupWrapper__subTitle'>
        This will remove your certification status and eligibility.
      </div>
      <div class='defaultPopupWrapper__textBottom'></div>

      <btnWrap *ngIf='deviceS.isDesktop$ | async'>
        <btn text='Go Back' bcg='white' (emit)='closeDeleteConfirmation()'></btn>
        <btn text='Proceed' bcg='red' (click)="confirmDeleteCertificate()"></btn>
      </btnWrap>
      <btnWrap *ngIf='deviceS.isMobile$ | async'>
        <btn text='Proceed' bcg='red' (click)="confirmDeleteCertificate()"></btn>
        <btn text='Go Back' bcg='white' (emit)='closeDeleteConfirmation()'></btn>
      </btnWrap>
    </div>
  </div>
</div>
