<div *ngIf='{ desktop: (deviceS.isDesktop$|async)!,
              mobile: (deviceS.isMobile$|async)!,
             } as obj' class='wrapperPopup'>
  <ng-container *ngIf='dataPopup.isSelectPaymentType; else viewAccountsTpl'>
    <headTitle text='Select how you want to get paid' svgRight='cross_grey&24' (svgRightEmit)='close()'
               typeText='text4-blueDark'></headTitle>

    <div class='content'>
      <div class='payment-type g-flex g-flex--align-center' (click)='onSelectPaymentMethod(ACCOUNT_METHODS.INSTANT)'>
        <div class='payment-type__icon'>
          <meSvg svgName='lightning_gold&20' [w]='24' [h]='24'></meSvg>
        </div>

        <div>
          <div class='o-text7'>Instant Payout - 2% QuickPay Fee</div>
          <div class='o-text8'>(Min $3.99)</div>
        </div>
      </div>

      <div class='payment-type g-flex g-flex--align-center' (click)='onSelectPaymentMethod(ACCOUNT_METHODS.STANDARD)'>
        <div class='payment-type__icon'>
          <meSvg svgName='hourglass'></meSvg>
        </div>

        <div>
          <div class='o-text7'>ACH Payout - Free</div>
          <div class='o-text8'>(5-7 business days)</div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #viewAccountsTpl>
    <headTitle [text]='dataPopup.isPayout ? "Available Payout" : (isEdit ? "Payment Methods" : "Add New Payment Method")'
               svgRight='cross_grey&24' (svgRightEmit)='close()' typeText='text4-blueDark'>
    </headTitle>

    <div *ngIf='dataPopup.isPayout && dataPopup.balance' class='o-text1 balance'>
      {{ (dataPopup.balance / 100) | currency:'USD' }}
    </div>

    <div class='content' [formGroup]='paymentMethod === ACCOUNT_METHODS.INSTANT ? formCard : formBank'>
      <div *ngIf='!isEdit; else editTpl' class='content-next'>
        <ng-container [ngSwitch]='paymentMethod'>
          <ng-container *ngSwitchCase='ACCOUNT_METHODS.INSTANT'>
            <field text='Name on Card' class='width70'>
              <input class='input' [formControlName]='FORM_FIELDS.NAME' [id]='FORM_FIELDS.NAME' mask='S* S*' placeholder='Name on Card'
                     autofocus />
              <!--              <inputCtrl [formControlName]='FORM_FIELDS.NAME' [id]='FORM_FIELDS.NAME' mask='S* S*' placeholder='Name on Card' autofocus></inputCtrl>-->
            </field>
            <field text='Zip Code' class='width26'>
              <input class='input' [formControlName]='FORM_FIELDS.ZIP' [id]='FORM_FIELDS.ZIP' mask='00000' placeholder='12345' />
            </field>

            <field text='Card number' class='width70'>
              <div [id]='FORM_FIELDS.NUMBER' class='input input--stripe'></div>
            </field>

            <field text='Expiry Date' class='width26'>
              <div [id]='FORM_FIELDS.EXPIRE' class='input input--stripe'></div>
            </field>

            <field text='CVV' class='width26'>
              <div [id]='FORM_FIELDS.CVV' class='input input--stripe'></div>
            </field>
            <!--<field
              text="Card number"
              class="width70">
              <div class="card-number g-flex g-flex&#45;&#45;align-center">
                <app-card-logo
                  *ngIf="cardBrand"
                  class="card-number__logo"
                  [height]="24"
                  [cardBrand]="cardBrand">
                </app-card-logo>

                <input
                  class="card-number__input"
                  [formControlName]="FORM_FIELDS.NUMBER"
                  [id]="FORM_FIELDS.NUMBER"
                  mask="0000 0000 0000 0000"
                  [validation]="false"
                  placeholder="1234 1234 1234 1234" />
              </div>
            </field>
            <field
              text="Expiry Date"
              class="width26">
              <input
                class="input"
                [formControlName]="FORM_FIELDS.EXPIRE"
                [id]="FORM_FIELDS.EXPIRE"
                mask="M0/0000"
                [dropSpecialCharacters]="false"
                placeholder="MM/YYYY" />
            </field>
            <field
              text="CVV"
              class="width26">
              <input
                class="input"
                [formControlName]="FORM_FIELDS.CVV"
                [id]="FORM_FIELDS.CVV"
                mask="000"
                placeholder="123" />
            </field>-->
          </ng-container>

          <ng-container *ngSwitchCase='ACCOUNT_METHODS.STANDARD'>
            <field text='Your routing number' class='width100'>
              <inputCtrl [formControlName]='FORM_FIELDS.ROUTING' [isNumber]='true'
                         [allowFirstZero]='true' placeholder='Your routing number'
                         [minlength]='9' [maxLength]='9' [isFocus]='true'></inputCtrl>
            </field>

            <field text='Your account number' class='width100'>
              <inputCtrl [formControlName]='FORM_FIELDS.ACCOUNT' [isNumber]='true' [allowFirstZero]='true'
                         placeholder='Your account number'></inputCtrl>
            </field>

            <field text='Confirm account number' class='width100'>
              <inputCtrl [formControlName]='FORM_FIELDS.ACCOUNT_CONFIRM' [isNumber]='true' [allowFirstZero]='true'
                         placeholder='Confirm account number'></inputCtrl>
            </field>
          </ng-container>
        </ng-container>
      </div>
      <ng-template #editTpl>
        <div *ngFor='let account of dataPopup.accounts' class='card-container g-flex'
             [class.card-container--selected]='account === selectedAccount'
             (click)='onAccountSelected(account)'>
          <app-account-payment class='g-flex__item'
                               [account]='account'
                               [mode]="dataPopup.isPayout ? 'payout' : (isEdit ? 'edit' : 'default')"
                               [isSelected]='account === selectedAccount'
                               [isDisabled]='isLoading'
                               (removeAccount)='onRemoveAccount($event)'
                               (defaultAccount)='onSetDefaultAccount($event)'>
          </app-account-payment>
        </div>

        <div class='g-flex g-flex--align-center g-flex--space-between'>
          <!--        <btn *ngIf='!dataPopup.isPayout' class='add-another' text='Add Another' bcg='transparent'-->
          <!--             svgLeft='plus_1&20' btnWidth='124' [disabled]='isLoading' (emit)='add()'></btn>-->
          <addAnother color='blueDark' (emit)='add()' [disabled]='isLoading'></addAnother>
        </div>
        <div *ngIf="showToggleSwitch && dataPopup.isPayout" class="toggle-switch-container instant" [ngClass]="{'instant-on': paymentMethodIsInstant, 'instant-off': !paymentMethodIsInstant}">
          <div class="instant__header">
            <div class="instant__title">{{ paymentMethodIsInstant ? 'Instant Eligible On!' : 'Instant Eligible Off!' }}</div> 
         <img src="assets/cross/cross_grey&24.svg" alt="Close" class="instant__close" (click)="closeToggleSwitch()">
         </div>
         <div class="instant__text" > 
          <ng-container *ngIf="!paymentMethodIsInstant; else onText">
            Your bank account is instant eligible! Turn on to receive an instant payout where funds typically appear in the associated bank account within 30 minutes. <b>A 2% QuickPay fee or min $3.99 will apply.</b>
          </ng-container>
          <ng-template #onText>
            Your bank account is Instant Eligible! Turn off to receive your money using a free standard ACH payout.
          </ng-template>
        </div> 
          <div style="display: flex; flex-direction: row; gap: 8px">
         <div class="toggle-switch" [class.on]="paymentMethodIsInstant" (click)="togglePaymentMethod()">
            <div class="toggle-switch-circle"></div>
          </div>
          <span style="font-weight: 600;">{{ paymentMethodIsInstant ? 'Turn Off' : 'Turn On' }}</span>
          </div>
        </div>
        <spinner *ngIf='isLoading' [diameter]='20' [isNoMargin]='true'></spinner>
      </ng-template>
    </div>

    <!--    === !!! NO DELETE !!! ==========================-->
    <ng-container>
      <div
        *ngIf="dataPopup.isPayout && selectedAccount && selectedAccount[ACCOUNT_EXTERNAL_FIELDS.METHOD] === ACCOUNT_METHODS.INSTANT"
        class="fee o-text7 g-flex g-flex--justify-center g-flex--align-center">
        -{{ getQuickPayFee() | currency:'USD' }} QuickPay Fee
        <meSvg
          class="fee__icon" svgName="lightning_gold&20" [w]="16" [h]="16">
          </meSvg>
      </div>
    </ng-container>

    <!--    <div class='actionsPopup g-flex g-flex&#45;&#45;align-center g-flex&#45;&#45;space-between'>-->
    <!--      <div class='actionsPopup__btns g-flex g-flex&#45;&#45;align-center g-flex__item'>-->
<!--    btnWidth='50%'-->
    <btnWrap *ngIf='!isEdit || dataPopup.isPayout' [type]='obj.desktop ? "row":"col"' [swapBtn]='obj.mobile'>
      <btn *ngIf='!isEdit' text='Cancel' bcg='white' (emit)='close()'></btn>

<!--      btnWidth='50%'-->
      <btn *ngIf='!isEdit' [text]='isEdit ? "Save": "Add"'
           [disabled]='isSaveDisabled() || (paymentMethod === ACCOUNT_METHODS.INSTANT && !isValidForm) || isLoading' bcg='blueDark'
           (click)='onSave()'></btn>

<!--      btnWidth='100%'-->
      <btn *ngIf='dataPopup.isPayout' class='btn-payout' [text]="'Proceed to Payout ' + getPayoutAmount()"
           [disabled]='!selectedAccount || isLoading || finalPayout === 0' bcg='blueDark' (click)='onPayout()'></btn>
    </btnWrap>
    <!--      </div>-->

    <spinner *ngIf='!isEdit && isLoading' [diameter]='20' [isNoMargin]='true'></spinner>
    <!--    </div>-->
  </ng-template>
</div>
