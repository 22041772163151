import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainService } from '@services/main.service';
import { PaymentService } from '@app/dir_group_assignor/payments/payment.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ClassPayMethodAndAmount,
  IPaymentMethod,
  IResCreatePaymentForCompetition,
  ISendObjCreatePaymentForCompetition,
  TypePay,
} from '@app/dir_group_assignor/payments/modelsForPayment';
import { FormControl, FormGroup, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { OtherService } from '@services/other.service';
import { FundService } from '@services/funds.service';
import { PlaidService } from '@services/plaid.service';
import { switchMap } from 'rxjs/operators';
import { DeviceService } from '@services/device.service';
import { SafePipe } from '@pipes/safe.pipe';
import {
  ToggleBankCardComponent,
} from '@app/dir_group_assignor/payments/additional-components/toggle-bank-card/toggle-bank-card.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { StripeFormComponent } from '@components/stripe-form/stripe-form.component';
import { LineComponent } from '@components/line/line.component';
import { BtnComponent } from '@components/btn/btn.component';
import { GetSelectedElemsPipe } from '@pipes/get-selected-elems.pipe';
import { PayMethodComponent } from '@app/dir_group_assignor/payments/additional-components/pay-method/pay-method.component';
import { SvgAndTextComponent } from '@components/__svg_img/svg-and-text/svg-and-text.component';
import { HelperClass } from '@classes/Helper-Classes';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PopupConfirmPaymentComponent } from '@components/__popup-windows/popup-confirm-payment/popup-confirm-payment.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';
import { StripeService } from '@services/stripe.service';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';

interface IPaymentsBalancePayForm {
  competitionId: FormControl<string>;
  amount: FormControl<number>;
  processingFee: FormControl<string>;
  transactionFee: FormControl<string>;
  amountPlusFee: FormControl<string>;
}

@UntilDestroy()
@Component({
  selector: 'app-add-new-payment',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SafePipe, ToggleBankCardComponent, InputCtrlComponent, StripeFormComponent, LineComponent, BtnComponent, GetSelectedElemsPipe, PayMethodComponent, SvgAndTextComponent, PopupConfirmPaymentComponent, MatTooltipModule, SvgComponent, BtnWrapComponent],
  templateUrl: './add-new-payment.component.html',
  styleUrls: ['./add-new-payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddNewPaymentComponent extends HelperClass implements OnInit {
  form!: FormGroup<IPaymentsBalancePayForm>;
  @ViewChild('paymentForm') paymentForm!: ElementRef<HTMLFormElement>;
  @Output() closeComponent = new EventEmitter<void>();
  @Input() closeHandler!: () => void;
  stripe: any;
  elements: any;
  // stripe!: Stripe;
  // elements!: StripeElements;
  showAddNewCard = false;


  selectedMethod?: IPaymentMethod;
  showConfirmPopup: boolean = false;

  // === FROM 2 VERSION ===========================================================
  srcIFrame?: any;
  payMethodAndAmount: ClassPayMethodAndAmount = new ClassPayMethodAndAmount(); // изменять ссылку, чтобы дочерний компонент отреагировал => new ClassPayMethodAndAmount(method, amount)
  rememberBankCtrl = new FormControl(false);
  showNewCard = false;
  transactionFeeRate?: string;
  clientSecret: string | undefined;
  setupIntentId: string | undefined;
  @Input() competitionId!: string;
  @Output() close = new EventEmitter<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    public mainS: MainService,
    public payS: PaymentService,
    public otherS: OtherService,
    public route: ActivatedRoute,
    public router: Router,
    public fundS: FundService,
    public stripeS: StripeService,
    public deviceS: DeviceService,
    public cd: ChangeDetectorRef,
    private dialog: MatDialog,
  ) {
    super(cd);


  }

  async ngOnInit(): Promise<void> {
    if (this.competitionId) {

      try {
        this.stripe = await loadStripe(this.stripeS.stripeKey);
        if (!this.stripe) {
          console.error('Stripe initialization failed.');
          return;
        }
        const response = await this.mainS.createPaymentMethodForCompetitionPrepare(this.competitionId).toPromise();
        this.clientSecret = response?.clientSecret;
        this.setupIntentId = response?.setupIntentId;

        if (this.clientSecret) {
          await this.openExistingConfirmationPopup(this.clientSecret);
        } else {
          console.error('Client secret not provided.');
        }
        this.elements = this.stripe.elements({
          clientSecret: this.clientSecret,
          appearance: { theme: 'stripe' },
        });

        if (!this.elements) {
          console.error('Stripe Elements initialization failed.');
          return;
        }

        const paymentElement = this.elements.create('payment', { layout: 'accordion' });
        paymentElement.mount('#payment-element');

      } catch (error) {
        console.error('Error fetching client secret:', error);
      } finally {

      }
    } else {
      console.error('Competition ID is not provided.');
    }
  }





  stripePromise = loadStripe(this.stripeS.stripeKey);



  async openExistingConfirmationPopup(clientSecret: string): Promise<void> {
    try {
      const stripe = await this.stripePromise;

      if (!stripe) {
        console.error('Stripe initialization failed.');
        return;
      }

      const elements = stripe.elements({
        clientSecret,
        appearance: { theme: 'stripe' },
      });

      const paymentElement = elements.create('payment', { layout: 'accordion' });
      paymentElement.mount('#payment-element');

    } catch (error) {
      console.error('Error during Stripe setup:', error);
    }
  }



  ngAfterViewInit(): void {
    const form = document.getElementById('payment-form') as HTMLFormElement;
    if (form) {
      form.addEventListener('submit', async (event) => {
        event.preventDefault();
        console.log('Form submitted.');
      });
    } else {
      console.error('Form element not found.');
    }
  }




  submitPaymentDetails(): void {
    const form = document.getElementById('payment-form') as HTMLFormElement;
    const returnUrl = `${window.location.origin}/payments/balances/pay?competitionId=${this.competitionId}`;
    form.addEventListener('submit', async (event) => {
      event.preventDefault();

      const { error, setupIntent } = await this.stripe.confirmSetup({
        elements: this.elements,
        confirmParams: {
          return_url: returnUrl
        },
        redirect: 'if_required',
      });



      const messageContainer = document.querySelector('#error-message');

      if (error) {
        if (messageContainer) {
          messageContainer.textContent = error.message || 'An error occurred.';
        }
      } else {
        console.log('Payment setup successfully submitted!');
        await this.handleSetupIntentResult(setupIntent);
        await this.mainS.retrievePaymentMethodForCompetition(this.competitionId, this.setupIntentId!)
          .subscribe(
            (response) => {
              console.log('Retrieved payment method:', response);
              window.location.reload();
            },
            (apiError) => {
              console.error('Error retrieving payment method:', apiError);
            }
          );
      }
    });
  }

  async handleSetupIntentResult(setupIntent: any): Promise<void> {
    const messageContainer = document.querySelector('#message');
    if (!messageContainer) return;

    switch (setupIntent.status) {
      case 'succeeded':
        messageContainer.textContent = 'Success! Your payment method has been saved.';
        break;

      case 'processing':
        messageContainer.textContent =
          "Processing payment details. We'll update you when processing is complete.";
        break;

      case 'requires_payment_method':
        messageContainer.textContent =
          'Failed to process payment details. Please try another payment method.';
        break;

      default:
        messageContainer.textContent = 'Unexpected status. Please try again later.';
    }
  }
  onCancel(): void {
    if (this.closeHandler) {
      this.closeHandler();
    }
    this.closeComponent.emit();
  }
}
