<div *ngIf='srcIFrame' class='o-flexCenter o-backdrop o-iframe'>
  <iframe [src]='srcIFrame|safe' [class]='(deviceS.isDesktop$|async) ? "o-w-popupDesktop":"o-w-popupMobile"'></iframe>
</div>

<ng-container *ngIf="showStripeForm">
  <div class="paymentCard">
    <svgAndText text='Add a Payment Card' leftSvgName='arrow_squareLeft&24' typeText='text1-blueDark'
      (leftSvgEmit)='showStripeForm = false'></svgAndText>
  </div>
  <div><br></div>
  <stripeForm textApply='Save' textCancel='Cancel' [isCreatePayMethod]='true'
    (createPayMethod)='handlePaymentMethodCreated($event)' (cancel)='showStripeForm = false'
    [competitionId]='ctrl.competitionId?.value!'>
  </stripeForm>
</ng-container>
<div *ngIf="!showStripeForm">
  <div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
              ACH: (fundS.link$|async)?.bank,
              CARD: (fundS.link$|async)?.card,
              typePay: (fundS.link$|async)?.bank ? "ACH":"CARD"
             } as obj' class='wrapperPaymentsBalancePay' [formGroup]='form'>

    <svgAndText *ngIf="obj.mobile" text='Balances' leftSvgName='arrow_squareLeft&24' typeText='text1-blueDark'
      (leftSvgEmit)='goBackBalances()'></svgAndText>

    <div *ngIf="obj.mobile" class='wrapperPaymentsBalancePay__subTitle'> Update your billing details and address </div>

    <div class='wrapperPaymentsBalancePay__title'>Notch Pay Balance</div>
    <div class='wrapperPaymentsBalancePay__subTitle'>
      Update your payment method or choose a method to add funds to your Notch Pay balance
    </div>
    <toggleBankCard></toggleBankCard>

    <!--  === CARD ========================================-->
    <ng-container *ngIf='obj.CARD'>
      <div *ngIf="obj.desktop" class='contentBalancePay o-wrap'>
        <ng-container [ngTemplateOutlet]='listMethods'></ng-container>

        <ng-container [ngTemplateOutlet]='blockAmount'></ng-container>
        <div class='blockAmount'>
          <div class='titleBlockBalancePay'>
            <div class="label-with-tool-tip">
              Processing Fee
              <meSvg svgName='question_grey&16'
                textMatTooltip='This processing fee helps cover the costs of securely handling your payment through our payment provider, including card network fees and transaction costs.'>
              </meSvg>
            </div>
            <!-- 2.9% -->
            <span *ngIf="obj.desktop">(3.95% + $0.30)</span>
          </div>
          <div class='titleBlockBalancePay__left'>
            <!--        [showDollar]='true'-->
            <inputCtrl formControlName='processingFee' [w]='(deviceS.isDesktop$|async) ? 240:"100%"'></inputCtrl>
          </div>
          <div *ngIf="obj.mobile" class='titleBlockBalancePay blockPay' style="padding-top: 5px;">
            <!-- 2.9% -->
            <span>(3.95% + $0.30)</span>
          </div>
        </div>
        <!-- <ng-container [ngTemplateOutlet]='blockAmountTransactionFee'></ng-container> -->
        <ng-container [ngTemplateOutlet]='btnsBalancePay'></ng-container>
      </div>

      <div *ngIf='showAddNewCard' class='addNewCardBalancePay o-wrap'>
        <div class='addNewCardBalancePay__title'>Add a Payment Card</div>
        <line *ngIf="obj.desktop"></line>
        <div class='addNewCardBalancePay__content'>
          <div *ngIf="obj.desktop" class='addNewCardBalancePay__content__title'>Card details</div>
          <stripeForm textApply='Save' textCancel='Cancel' [isCreatePayMethod]='true'
            (createPayMethod)='getCurrentMethods($event)' (cancel)='showAddNewCard=false'
            [competitionId]='ctrl.competitionId?.value!'></stripeForm>
        </div>
      </div>

      <div class='contentBalancePay o-wrap' *ngIf="obj.mobile">
        <ng-container [ngTemplateOutlet]='listMethods'></ng-container>
      </div>

      <div class='contentBalancePay o-wrap mobileBoxWrapper' *ngIf="obj.mobile">
        <ng-container [ngTemplateOutlet]='blockAmount'></ng-container>
        <div class='blockAmount'>
          <div class='titleBlockBalancePay'>
            <div class="label-with-tool-tip">
              Processing Fee
              <meSvg svgName='question_grey&16'
                textMatTooltip='This processing fee helps cover the costs of securely handling your payment through our payment provider, including card network fees and transaction costs.'>
              </meSvg>
            </div>
            <!-- 2.9% -->
            <span *ngIf="obj.desktop">(3.95% + $0.30)</span>
          </div>
          <div class='titleBlockBalancePay__left'>
            <!--        [showDollar]='true'-->
            <inputCtrl formControlName='processingFee' [w]='(deviceS.isDesktop$|async) ? 240:"100%"'></inputCtrl>
          </div>
          <div *ngIf="obj.mobile" class='titleBlockBalancePay blockPay' style="padding-top: 5px;">
            <!-- 2.9% -->
            <span>(3.95% + $0.30)</span>
          </div>
        </div>
        <!-- <ng-container [ngTemplateOutlet]='blockAmountTransactionFee'></ng-container> -->
        <ng-container [ngTemplateOutlet]='btnsBalancePay'></ng-container>
      </div>
    </ng-container>

    <!--  === BANK ========================================-->
    <ng-container *ngIf='obj.ACH'>
      <div *ngIf="obj.desktop && !showAddNewPayment" class='contentBalancePay o-wrap'>
        <div class='contentBalancePay__title'>Payment method</div>
        <div class='contentBalancePay__subTitle'>Your primary bank method is used for all recurring payments</div>
        <line></line>
        <ng-container [ngTemplateOutlet]='listMethods'></ng-container>
        <ng-container [ngTemplateOutlet]='blockAmount'></ng-container>
        <!-- <ng-container [ngTemplateOutlet]='blockAmountTransactionFee'></ng-container> -->
        <ng-container [ngTemplateOutlet]='btnsBalancePay'></ng-container>
      </div>

      <div *ngIf="obj.mobile" class='contentBalancePay o-wrap'>
        <div class='contentBalancePay__title'>Payment method</div>
        <div class='contentBalancePay__subTitle'>Your primary bank method is used for all recurring payments</div>
        <line></line>
        <ng-container [ngTemplateOutlet]='listMethods'></ng-container>
      </div>

      <div *ngIf="obj.mobile && !showAddNewPayment" class="mobileBoxWrapper">
        <ng-container [ngTemplateOutlet]='blockAmount'></ng-container>
        <!-- <ng-container [ngTemplateOutlet]='blockAmountTransactionFee'></ng-container> -->
        <ng-container [ngTemplateOutlet]='btnsBalancePay'></ng-container>
      </div>
    </ng-container>

    <!--=== ng-template ======================================================================-->
    <!--  === BUTTONS ====================-->
    <ng-template #btnsBalancePay>
      <div class='btnsBalancePay'>
        <btn *ngIf='!mainS.forProd && ((payS.methodsSub$|async)!|getSelectedElems)?.length'
          text='FOR TEST => DELETE Bank payment method' bcg='white' (emit)='deletePaymentMethodsCompetition()'
          btnHeight='40'></btn>
        <!--      payS.currentLink$.next({linkPayments: "balances"})-->
        <btn text='Cancel' bcg='white' (emit)='router.navigate(["balances"])'
          btnWidth='(deviceS.isDesktop$|async) ? 79:"100%"' btnHeight='40'></btn>
        <!--      from 2 version (emit)='obj.CARD ? payForSavedCard() : payWithSavedBank()' -->
        <btn text='Pay {{ctrl.amountPlusFee?.value|currency}}' bcg='blueDark' btnHeight='40'
          (emit)='createPaymentForCompetition()'
          [disabled]='!form.valid || !((payS.methodsSub$|async)!|getSelectedElems)?.length || reqPending'></btn>
      </div>
    </ng-template>

    <ng-template #blockAmount>
      <div class='blockAmount'>
        <div class='titleBlockBalancePay'>Amount</div>
        <div class='titleBlockBalancePay__left'>
          <inputCtrl formControlName='amount' [w]='(deviceS.isDesktop$|async) ? 240:"100%"' [showDollar]='true'
            [isNumber]='true' (changeVal)='changeVal($event,"amount")'></inputCtrl>
        </div>
      </div>
    </ng-template>

    <!-- <ng-template #blockAmountTransactionFee>
      <div class='blockAmount'>

        <div class='titleBlockBalancePay'>
          <div class="label-with-tool-tip">
            Notch Pay Transaction Fees
            <meSvg svgName='question_grey&16'
              textMatTooltip='We apply a transaction fee when funds are added to your Notch Pay balance. This fee is non-refundable, even if any unused balance is later withdrawn to your original payment method.'>
            </meSvg>
          </div>
          <span *ngIf="obj.desktop">{{ transactionFeeRate }}</span>
        </div>
        <div class='titleBlockBalancePay__left'>
          <inputCtrl formControlName='transactionFee' [w]='(deviceS.isDesktop$|async) ? 240:"100%"'></inputCtrl>
        </div>
        <div *ngIf="obj.mobile" class='titleBlockBalancePay' style="padding-top: 5px;">
          <span>{{ transactionFeeRate }}</span>
        </div>
      </div>
    </ng-template> -->

    <ng-template #listMethods>
      <div *ngIf="!showAddNewPayment" class='contentBalancePay__block'>
        <div class='titleBlockBalancePay'>{{obj.CARD ? "Card" : "Bank"}} details
          <span>Select default payment method</span>
        </div>
        <div class='contentBalancePay__block__wrapCards'>
          <payMethod *ngFor='let payMethod of (payS.methodsSub$|async)' [payMethod]='payMethod'
            class='contentBalancePay__block__wrapCards__item' (emit)='selectPayMethod($event,payMethod,obj.typePay)'
            [isChx]='true'></payMethod>

          <!-- <svgAndText *ngIf='mainS.forProd' [text]='obj.CARD ? "Add new payment method":"Add new bank account"' leftSvgName='plus_1&20' typeText='text7-blueDark'
                    (click)='obj.CARD ? addNewCard() : getTokenAndLibraryPlaidCreateBank()'></svgAndText> -->
          <svgAndText [text]="obj.CARD ? 'Add new payment method' : 'Add new bank account'" leftSvgName="plus_1&20"
            typeText="text7-blueDark" (click)="obj.CARD ? addNewCard() : toggleAddNewPayment()"
            [ngClass]="{'addNewBankAccountMobileStyles': obj.mobile}">
          </svgAndText>

        </div>
        <div id="payment-form-container"></div>
      </div>
    </ng-template>

  <ng-container *ngIf="showAddNewPayment && obj.ACH">
    <app-add-new-payment  [closeHandler]="handleAddNewPaymentClose.bind(this)" [competitionId]="ctrl.competitionId?.value!"></app-add-new-payment>
  </ng-container>
</div>