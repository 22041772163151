import { ChangeDetectorRef, Component } from '@angular/core';
import { ReactWrapperComponent } from "./components/react-wrapper/react-wrapper.component";
import { ComingSoonMobile } from "../dashboard/components/coming-soon-mobile/coming-soon-mobile.component";
import { WrapPageDirective } from '@directives/wrap-page.directive';
import { FiltersService } from '@components/filters/filters.service';
import { HelperClass } from '@classes/Helper-Classes';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { MeTableService } from '@components/_table/me-table.service';
import { CommonModule } from '@angular/common';
import { EmbeddableTabbedList, embeddableTabList } from './embeddableList';

@UntilDestroy()
@Component({
    selector: 'reporting',
    styleUrls: ['./reporting.component.scss'],
    standalone: true,
    templateUrl: './reporting.component.html',
    imports: [ComingSoonMobile, CommonModule, ReactWrapperComponent, WrapPageDirective],
    providers: [FiltersService, MeTableService, SettingsRequestService],
})
export class ReportingComponent extends HelperClass {
    variables: any| undefined = undefined;
    filtersReady: boolean = true;
    tabs: Array<EmbeddableTabbedList> = embeddableTabList;
    selectedTab: EmbeddableTabbedList = embeddableTabList[0];

    constructor(
        public cd: ChangeDetectorRef,
    ) {
        super(cd);
    }

    onFinishLoadFilters = (value:boolean) => {
        this.filtersReady = value;        
    } 

    onVariableUpdated = (newVariables:any) => {
        this.variables = {
            ...this.variables,
            ...newVariables
        };
        this.cd.detectChanges();
    } 

    onClickItemTab(item: EmbeddableTabbedList): void {
        this.selectedTab = item;
    }
}
