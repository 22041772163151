import { ClassAvailability } from '@models/ClassAvailability';
import { IUnavailableDate } from '@models/IUnavailableDate';
import { ILocation } from '@models/location';
import { ClassGroup } from '@models/IGroup';
import {
  ClassDrop,
  ClassYesNo,
  getArrayDropFromString,
  getDropFromString,
  preparePropertyFromDropForSendToServer,
} from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { ClassCompetitionLocation } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { TColor } from '@models/ICssStyles';
import { UtilsService } from '@services/utils.service';

export const userRole_OFFICIAL: Extract<TUserRoleUpperCase, 'OFFICIAL'> = 'OFFICIAL';
export const userRole_GROUP_ASSIGNOR: Extract<TUserRoleUpperCase, 'GROUP_ASSIGNOR'> = 'GROUP_ASSIGNOR';
export const userRole_SUB_ASSIGNOR: Extract<TUserRoleUpperCase, 'SUB_ASSIGNOR'> = 'SUB_ASSIGNOR';
export const userRole_ADMIN: Extract<TUserRoleUpperCase, 'ADMIN'> = 'ADMIN';
export type TUserRoleUpperCase = 'OFFICIAL' | 'GROUP_ASSIGNOR' | 'SUB_ASSIGNOR' | 'ADMIN';
export type TUserRoleTitleCase = 'Official' | 'Group-Assignor' | 'Sub-Assignor' | 'Admin';
export type TUserRoleLowercase = 'official' | 'group_assignor' | 'sub_assignor' | 'admin';
export const arrUserRoleUpperCase: Array<TUserRoleUpperCase> = [userRole_OFFICIAL, userRole_GROUP_ASSIGNOR, userRole_SUB_ASSIGNOR, userRole_ADMIN];
export const arrUserRoleTitleCase: Array<TUserRoleTitleCase> = ['Official', 'Group-Assignor', 'Sub-Assignor', 'Admin'];
export const arrUserRoleLowercase: Array<TUserRoleLowercase> = ['official', 'group_assignor', 'sub_assignor', 'admin'];
export const arrUserRole: Array<TUserRoleUpperCase | TUserRoleTitleCase | TUserRoleLowercase> = [...arrUserRoleUpperCase, ...arrUserRoleTitleCase, ...arrUserRoleLowercase];
// export const arrUserRoleStringUpperCase: Array<TUserRoleUpperCase> = ['OFFICIAL', 'GROUP_ASSIGNOR', 'SUB_ASSIGNOR', 'ADMIN'];
export type TUserRoleDrop = ClassDrop & { titleCase: TUserRoleTitleCase, upperCase: TUserRoleUpperCase };
// export const arrUserRoleDrop = arrUserRoleStringUpperCase.map(el => getDropFromString(el, 'arrUserRoleDrop')) as Array<TUserRoleDrop>;
export const arrUserRoleDrop: Array<TUserRoleDrop> = [
  { titleCase: 'Official', upperCase: userRole_OFFICIAL },
  { titleCase: 'Group-Assignor', upperCase: userRole_GROUP_ASSIGNOR },
  { titleCase: 'Sub-Assignor', upperCase: userRole_SUB_ASSIGNOR },
  { titleCase: 'Admin', upperCase: userRole_ADMIN },
];

// console.log('arrUserRoleDrop :', arrUserRoleDrop);

export function getUserRoleTitleCase(userRole: TUserRoleUpperCase | TUserRoleLowercase | TUserRoleTitleCase): TUserRoleTitleCase {
  return EUserRole_toTitleCase[userRole];
}

export enum EUserRole_toTitleCase {
  OFFICIAL = 'Official',
  official = 'Official',
  Official = 'Official',

  ADMIN = 'Admin',
  admin = 'Admin',
  Admin = 'Admin',

  GROUP_ASSIGNOR = 'Group-Assignor',
  group_assignor = 'Group-Assignor',
  'Group-Assignor' = 'Group-Assignor',
  'group-assignor' = 'Group-Assignor',

  SUB_ASSIGNOR = 'Sub-Assignor',
  sub_assignor = 'Sub-Assignor',
  'Sub-Assignor' = 'Sub-Assignor',
  'sub-assignor' = 'Sub-Assignor',
}

// ===============================================
export type TUserSignup = Pick<ClassUser, 'id' | 'emailValidated' | 'phoneValidated' | 'status'>;

export type TUserStatus = 'REGISTERED' | 'PENDING' | 'EXPIRED' | 'ACTIVE';

export enum UserRegisteredStatus {
  NONE = 'NONE',
  INVITATION_EXPIRED = 'INVITATION_EXPIRED',
  PENDING = 'PENDING',
  EMAIL_VERIFIED = 'EMAIL_VERIFIED',
  NAME_ENTERED = 'NAME_ENTERED',
  RESIDENTIAL_ADDRESS_ENTERED = 'RESIDENTIAL_ADDRESS_ENTERED',
  MOBILE_VERIFIED = 'MOBILE_VERIFIED',
  MOBILE_ENTERED = 'MOBILE_ENTERED',
  DATE_OF_BIRTH = 'DATE_OF_BIRTH',
  EMERGENCY_CONTACT = 'EMERGENCY_CONTACT',
  START_OFFICIATING = 'START_OFFICIATING',
  REGISTERED = 'REGISTERED',
}

export enum EUserRole {
  OFFICIAL = 'OFFICIAL',
  ADMIN = 'ADMIN',
  GROUP_ASSIGNOR = 'GROUP_ASSIGNOR',
  SUB_ASSIGNOR = 'SUB_ASSIGNOR',
  official = 'official',
  group_assignor = 'group_assignor',
  sub_assignor = 'sub_assignor',
}

export enum AvailabilityStatus {
  NOT_SET = 'NOT_SET', // svgName for officials for page Assign => question_grey_filled&24 (знак вопроса в сером круге)
  AVAILABLE = 'AVAILABLE', // svgName for officials for page Assign => circle_chx2&16 (галочка в зеленом круге)
  UNAVAILABLE = 'UNAVAILABLE', // svgName for officials for page Assign => circle_crossRed2&16 (крестик в зеленом круге)
  ASSIGNED_TO_ANOTHER_GAME = 'ASSIGNED_TO_ANOTHER_GAME', // svgName for officials for page Assign => circle_chx7&16 (галочка в оранжевом круге)
}

export const arrCertificationNameString: Array<string> = ['Please select', 'United States Soccer Federation (USSF)', 'USA Volleyball', 'USA Hockey'];
export const arrEmergencyRelationshipString: Array<string> = ['Parent', 'Guardian', 'Spouse', 'Sibling', 'Family', 'Trusted Friend'];
export const arrCertificationNameDrop: Array<ClassDrop> = getArrayDropFromString(arrCertificationNameString, 'arrCertificationNameDrop');
export const arrEmergencyRelationshipDrop: Array<ClassDrop> = getArrayDropFromString(arrEmergencyRelationshipString, 'arrEmergencyRelationshipDrop');

export type TAvailabilityStatus = 'NOT_SET' | 'AVAILABLE' | 'UNAVAILABLE' | 'ASSIGNED_TO_ANOTHER_GAME';

export interface IAvailabilitySameDate {
  gameTime: string,
  competitionName: string,
  gameAssignStatus: string,
  gameAddress: string;
  duration?: number;
  groupName?: string;
}

export interface IAvailabilityGameDetails {
  availabilityStatus: TAvailabilityStatus,
  distance: number; // string;
  availabilitySameDate?: Array<IAvailabilitySameDate>;
}

export class ClassAvailabilitySameDate {
  gameTime?: string;
  competitionName?: string;
  gameAssignStatus?: string;
  gameAddress?: string;

  constructor(obj: ClassAvailabilitySameDate) {
    if (!obj) return {};
    Object.entries(obj)?.forEach((el) => {
      const key = el[0] as keyof ClassAvailabilitySameDate;
      this[key] = el[1];
    });
    return { ...this };
  }
}

export class ClassAvailabilityGameDetails {
  availabilityStatus?: TAvailabilityStatus;
  distance?: number; // string;
  availabilitySameDate?: Array<ClassAvailabilitySameDate>;

  constructor(obj: ClassAvailabilityGameDetails) {
    if (!obj) return {};
    Object.entries(obj)?.forEach((el) => {
      const key = el[0] as keyof ClassAvailabilityGameDetails;
      this[key] = el[1];
    });
    return { ...this };
  }
}

export interface SelfLimitedResponse {
  selfApplyLimit: string;
}

interface OrganizationUserData {
  organization: string;
  organizationUserId: string;
  refereeExperience: number;
}

export interface IMainLicense {
  license: {
    organization: string;
    name: string;
    id: string;
    category: string;
    status: string;
  };
  issueDate: string;
  expiryDate: string;
}

export type TypeLastAvailability = 'lastAvailabilityReview' | 'lastAvailabilityUpdated';

// !!! при получении с сервера вызывать
export class ClassUser {
  id?: string; // !!! в getMe() это userId
  userId?: string; // !!! в запросе api/core/officials/v1 правильно приходит userId & id
  officialGroups?: Array<ClassDrop & Pick<ClassGroup, 'id' | 'name'>>; // !!! FOR OFFICIALS список групп
  // officialGroups?: Array<Pick<ClassGroup, 'id' | 'name'>>; // !!! FOR OFFICIALS список групп
  onboardingSkipped?: boolean; // !!! если все шаги на дашборду проскипал, то отправлять на апдейт юзера true.

  name?: string; // competitions/users приходит это поле
  firstName?: string;
  secondName?: string;
  preferredName?: string;
  nameFormatted?: string; // !!! (firstName + secondName) || email
  roleCurrent?: TUserRoleUpperCase;
  roleCurrentDrop?: TUserRoleDrop;
  address?: ILocation;
  emailValidated?: boolean;
  phoneValidated?: boolean;
  status?: TUserStatus;

  email?: string;
  password?: string;
  role?: string; // TUserRoleUpperCase  при регистрации судьи для отправки на сервер
  roleDrop?: TUserRoleDrop; // при регистрации судьи для отправки на сервер
  phone?: string;
  phone_formatted?: string; // +X (XXX) XXX-XXXX
  signType?: string; // !!! для чего это надо узнать потом
  enabled?: boolean; // !!! для чего это надо узнать потом
  userRegisteredStatus?: string;
  pictureUrl?: string;

  lastActive?: string; // !!! "2024-02-13T15:09" => 11 Dec 2023
  lastActive_formatted?: string; // !!! 11 Dec 2023
  age?: string | number;
  age_less16?: boolean; // age < 16
  age_16_18?: boolean; // age >= 16 && age < 18
  age_more18?: boolean; // age >= 18
  age_color?: TColor; // age_less16 => o-color-red // age_16_18 => o-color-yellow // age_more18 => o-color-blueDark

  dateOfBirth?: string;
  dateOfBirth_formatted?: string; // MM/dd/YYY

  // !!! FOR OFFICIAL ===========================
  groupId?: string; // for signUp official
  key?: string; // for signUp official
  acceptTerms?: boolean; // for signUp official
  emergencyName?: string; // Viktoria Golder
  emergencyRelationship?: string; // Sister
  emergencyRelationshipDrop?: ClassDrop;
  emergencyPhoneNumber?: string; // 155555555
  socialSecurity?: string; // SNN XX-XXX-8888
  startOfficiating?: string; // year // What year did you start officiating?
  certificationName?: string; // 'United States Soccer Federation (USSF)', 'USA Volleyball', 'USA Hockey', 'Team No Show' // Under which governing body did you obtain a certification?
  CertificateRegistration?: String;
  certificationNameDrop?: ClassDrop;
  certified?: boolean; // Are you currently certified as an official? 'Yes'|'No'
  certifiedDrop?: ClassYesNo;
  stripeAccount?: boolean; // !!! есть ли у этого юзера созданый страйп аккаунт
  organizationUserData?: OrganizationUserData;
  mainLicense?: IMainLicense;
  secondaryEmail?: string;
  secondaryEmailValidated?: boolean;

  availabilities?: ClassAvailability[];
  unavailableDates?: IUnavailableDate[];
  lastAvailabilityReview?: string; // 2024-07-23T09:14:21.216254212
  lastAvailabilityUpdated?: string; // 2024-04-04T11:50:46.877905
  lastDateAvailability?: TypeLastAvailability; // какая из этих дат последняя (lastAvailabilityReview || lastAvailabilityUpdated)

  isSelect?: boolean; // for checkBox
  // isSelected?: boolean;
  availabilityByGame?: IAvailabilityGameDetails;
  gameReminderOn?: boolean;

  constructor(user: ClassUser) {
    if (!user) return {};
    Object.entries(user)?.forEach((el) => {
      const key = el[0] as keyof ClassUser;
      this[key] = el[1];
    });

    if (user.firstName || user.secondName) {
      this.nameFormatted = (user.firstName || '') + ' ' + (user.secondName || '');
    } else if (user.preferredName) {
      this.nameFormatted = user.preferredName;
    } else if (user.email) {
      this.nameFormatted = user.email;
    } else {
      this.nameFormatted = '-';
    }

    if (user.dateOfBirth) {
      this.dateOfBirth_formatted = UtilsService.datePipe_transform(user.dateOfBirth, 'MM/dd/YYY')!;
      this.age = UtilsService.getAgeByDateOfBirth(user.dateOfBirth);
    }

    if (user.age !== undefined) {
      const age = Number(user.age); // Convert to number
      this.age_less16 = age < 16;
      this.age_16_18 = age >= 16 && age < 18;
      this.age_more18 = age >= 18;

      // age_less16 => o-color-red // age_16_18 => o-color-yellow // age_more18 => o-color-blueDark
      if (this.age_less16) this.age_color = 'red';
      if (this.age_16_18) this.age_color = 'yellow';
      if (this.age_more18) this.age_color = 'blueDark';
    }

    if (user.phone) this.phone_formatted = UtilsService.getFormatPhone(user.phone); // +X (XXX) XXX-XXXX

    if (user.lastActive) this.lastActive_formatted = UtilsService.datePipe_transform(user.lastActive, 'd MMM yyyy')!;

    if (user.availabilities?.length) {
      this.availabilities = user.availabilities?.map(availability => new ClassAvailability(availability));
    }

    if (user.lastAvailabilityReview && user.lastAvailabilityUpdated) {
      this.lastDateAvailability = UtilsService.compareDate(user.lastAvailabilityReview, user.lastAvailabilityUpdated) ? 'lastAvailabilityReview' : 'lastAvailabilityUpdated';
    } else if (user.lastAvailabilityReview || user.lastAvailabilityUpdated) {
      this.lastDateAvailability = user.lastAvailabilityReview ? 'lastAvailabilityReview' : 'lastAvailabilityUpdated';
    }

    // this.officialGroups = user.officialGroups?.map(el => new ClassGroup(el));
    this.officialGroups = UtilsService.getArrayDropItemById(user.officialGroups, 'name')?.map(el => new ClassGroup(el));
    this.roleCurrentDrop = getDropFromString(user.roleCurrent!, 'ClassUser user.roleCurrent') as TUserRoleDrop;
    this.address = new ClassCompetitionLocation(user.address);
    this.roleDrop = getDropFromString(user.role!, 'ClassUser user.role') as TUserRoleDrop;
    this.emergencyRelationshipDrop = getDropFromString(user.emergencyRelationship!, 'ClassUser user.emergencyRelationship');
    this.certificationNameDrop = getDropFromString(user.certificationName!, 'ClassUser user.certificationName');
    // console.log('user.certified :', typeof user.certified, user.certified, user)
    if (typeof user.certified === 'boolean') this.certifiedDrop = new ClassYesNo(user.certified);
    return { ...user, ...this };
  }

  // !!! эти property НЕ нужно отправлять на сервер. Перед отправкой они удаляются
  static arrPropertyForDeleteBeforeSendToServer: Array<keyof ClassUser> = [
    'age_less16', 'age_16_18', 'age_more18', 'age_color', 'phone_formatted', 'lastActive_formatted',
  ];

  // !!! property-исключения которые нужно обрабатывать для отправки на сервер по-другому. Например sports = "BASKETBALL,BASEBALL"
  // private readonly arrExcludesProperty?: Array<keyof ClassCompetition> = [''];

  // !!! перед отправкой на сервер доставать из IDrop.upperCase и записывать в соответствующий property
  // !!! И удалять IDrop, т.к. на сервер они не доллжны отправляться
  // !!! Все property которые в конце названия имеют "Drop" нужно в этом методе обрабатывать
  static preparePropertyFromDropForSendToServer(obj?: ClassUser): ClassUser {
    if (!obj) return {};
    const objForSendToServer: ClassUser = obj;
    ClassUser.arrPropertyForDeleteBeforeSendToServer.forEach((el) => delete objForSendToServer[el]);

    // !!! prepare Property Without Drop In Name
    // !!! если в конструкторе текущего класса вызывал создание другого класса, то здесь надо вызвать preparePropertyFromDropForSendToServer
    objForSendToServer.officialGroups = obj.officialGroups?.map(el => ClassGroup.preparePropertyFromDropForSendToServer(el));
    objForSendToServer.address = ClassCompetitionLocation.preparePropertyFromDropForSendToServer(obj.address);

    return preparePropertyFromDropForSendToServer(objForSendToServer);
  }

}
